import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../assets/tableup.svg";
import tabledown from "../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import RowListing from "./rowListings";

const DataTable = ({
  transactions,
  usdPrice,
  user,
  setTransactions,
  setOrderDirection,
  setOrderField,
  tomiPrice,
}) => {
  const [rend, setRend] = useState(false);

  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("createdAt");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">Hash </div>
                </th>
                {/* <th>
                  <div className="parent-tag">
                    Sale Type{" "}
                  </div>
                </th> */}
                {/* <th>
                  <div className="parent-tag">
                    Name{" "}
                  </div>
                </th> */}
                <th>
                  <div className="parent-tag">Recpient Wallet </div>
                </th>
                <th>
                  <div className="parent-tag">Buyer Email </div>
                </th>
                <th>
                  <div className="parent-tag">Wallet Address </div>
                </th>
                <th>
                  <div className="parent-tag">Amount Spent</div>
                </th>
                <th>
                  <div className="parent-tag">
                    GEMS Purchased{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("totalDop");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((item, index) => {
                return (
                  <RowListing
                    user={user}
                    item={item}
                    index={index}
                    usdPrice={usdPrice}
                  />
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="mobiledesignss d-none">
          {transactions?.map((item, index) => {
            return (
              <RowListing
                user={user}
                item={item}
                index={index}
                usdPrice={usdPrice}
              />
            );
          })}
        </div>
      </section>
    </>
  );
};

export default DataTable;
