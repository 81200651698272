import React, { useState, useEffect } from "react";
import "./alltransaction.scss";
import DataTable from "./DataTable/DataTable";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Api_Url } from "../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";

const AllTransaction = ({
  user,
  setindexwait,
  tomiPrice,
  usdPrice,
  btcPrice,
}) => {
  const [codeDataCall, setCodeDataCall] = useState();
  const tok = localStorage?.getItem("accessToken");

  const [transactionApiCalled, setTransactionApiCalled] = useState(false);
  const [data, setData] = useState([]);
  const [totalT, setTotalT] = useState(0);
  const [purchases, setPurchases] = useState(null);
  const [dops, setDops] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [total, setTotal] = useState(0);
  // const [usdPrice, setUsdPrice] = useState(0);
  const [dopPrice, setDopPrice] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [code, setCode] = useState([]);
  const [pc, setPC] = useState(0);
  const [pmc, setPMC] = useState(0);
  const [nrc, setNRC] = useState(0);
  const [lc, setLC] = useState(0);
  const [dpc, setDPC] = useState(0);
  const [lec, setLEC] = useState(0);
  const [mm, setmm] = useState(0);
  const [ss, setss] = useState(0);
  const [gg, setgg] = useState(0);
  const [pee, setPEE] = useState(0);
  const [peu, setPEU] = useState(0);
  const [pet, setPET] = useState(0);

  const [pdp, setPDP] = useState(0);
  const [ind, setIND] = useState(0);
  const [dt, setDT] = useState(0);
  const [adminobj, setadminobj] = useState();
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [myTree, setMyTree] = useState(false);
  const [tokenNftStats, setTokenNftStats] = useState();

  const [typeFilter, setTypeFilter] = useState("");
  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");
  const history = useHistory();

  // const [tomiPrice, setTomiPrice] = useState("");
  const userObj = JSON.parse(localStorage.getItem("user"));
  // useEffect(() => {
  //   getTomiPrice();
  //   axios
  //     .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
  //     .then((res) => {
  //       setUsdPrice(res?.data?.USD);
  //       // setEthUsd(res.data);
  //     });
  // }, []);

  // const getTomiPrice = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.coingecko.com/api/v3/simple/price`,
  //       {
  //         params: {
  //           ids: "tomiNet",
  //           vs_currencies: "usd",
  //         },
  //       }
  //     );

  //     const data = response?.data;
  //     const priceInUSD = data["tominet"]?.usd;

  //     setTomiPrice(priceInUSD);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     // throw error;
  //   }
  // };

  useEffect(() => {
    // getTotalList();
    if (usdPrice) {
      getTransactionsData();
      // getTotalList2();
    }
  }, [
    usdPrice,
    user,
    search,
    typeFilter,
    orderField,
    orderDirection,
    page,
    myTree,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const getTransactionsData = () => {
    setTransactions([]);
    setTransactionApiCalled(false);

    var config = {
      method: "get",
      url: `${Api_Url}/users/all-transactions?privateSale=GEMS&limit=10&offset=${
        page + 1
      }`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };
    let dynamicQuery = config.url;
    if (search) {
      dynamicQuery += `&${searchBy}=${search}`;
    }
    if (typeFilter) {
      dynamicQuery += `&leaderType=${typeFilter}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }
    if (user?.role == "god_agent") {
      dynamicQuery += `&myTree=${myTree}`;
    }
    config.url = dynamicQuery;

    axios(config)
      .then(function (res) {
        // console.log("trx data: ", res?.data?.data?.transactions);
        setTransactions(res?.data?.data?.transactions);
        setPageCount(res?.data?.data?.pages);
        setTransactionApiCalled(true);

        // setLoader(false);
      })
      .catch(function (error) {
        // setLoader(false);
        setTransactionApiCalled(true);
      });
  };

  // const handlePageClick = (e) => {
  //   const selectedPage = e.selected;
  //   // let a = JSON.stringify(inputs?.auctionCounted);
  //   let skip = "";
  //   if (e.selected > 0) {
  //     skip = JSON.stringify(e.selected * 10);
  //     setPage(selectedPage);
  //   } else {
  //     skip = JSON.stringify(0);
  //     setPage(0);
  //   }
  //   var data = null;
  //   if (code.length > 0) {
  //     if (code?.length > 0) {
  //       var data = JSON.stringify({
  //         query: `query MyQuery {
  //                     investments(
  //                       where: {code_code_in: ${JSON.stringify(code)}}
  //                       orderBy: blockTimestamp
  //                       first: 10
  //                       skip: ${skip}
  //                       orderDirection: desc) {
  //                       tokenSelected
  //                       by
  //                       blockTimestamp
  //                       code_AgentAddress
  //                       code_AgentPercentage
  //                       code_GodAgentAddress
  //                       code_GodAgentPercentage
  //                       code_SuperAgentAddress
  //                       code_SuperAgentPercentage
  //                       dopPurchased
  //                       code_code
  //                       id
  //                       index
  //                       price
  //                       round
  //                       transactionHash
  //                       AgentAmount
  //                       amountInvested
  //                       GodAgentAmount
  //                       MegaAgentAmount
  //                       SuperAgentAmount
  //                     }
  //                   }`,
  //         variables: {},
  //       });
  //       var config = {
  //         method: "post",
  //         url: "https://api.studio.thegraph.com/query/51007/dop-sale/version/latest",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         data: data,
  //       };
  //       axios(config)
  //         .then((response) => {
  //           if (response?.data?.data?.investments?.length > 0) {
  //             setTransactions(response?.data?.data?.investments);
  //           } else {
  //             setTransactions([]);
  //             setData([]);
  //           }
  //         })
  //         .catch((err) => {
  //           setTransactions([]);
  //           setData([]);
  //           console.log(err);
  //         });
  //     } else {
  //       setData([]);
  //       setTransactions([]);
  //     }
  //   } else if (user?.role !== "admin") {
  //     if (user?.role === "agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(
  //               orderBy: blockTimestamp
  //               orderDirection: desc
  //               first: 10
  //                skip: ${skip}
  //               where: { code_AgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "super_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               first: 10
  //                 skip: ${skip}
  //               where: { code_SuperAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "mega_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               first: 10
  //                 skip: ${skip}
  //               where: { code_MegaAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "god_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               first: 10
  //                 skip: ${skip}
  //               where: { code_GodAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     }
  //   } else {
  //     data = JSON.stringify({
  //       query: `query MyQuery {
  //               investments(
  //                 orderBy: blockTimestamp
  //                 orderDirection: desc
  //                 first: 10
  //                 skip: ${skip}
  //               ) {
  //                   tokenSelected
  //                   by
  //                   blockTimestamp
  //                   code_AgentAddress
  //                   code_AgentPercentage
  //                   code_GodAgentAddress
  //                   code_GodAgentPercentage
  //                   code_SuperAgentAddress
  //                   code_SuperAgentPercentage
  //                   dopPurchased
  //                   code_code
  //                   id
  //                   index
  //                   price
  //                   round
  //                   transactionHash
  //                   AgentAmount
  //                   amountInvested
  //                   GodAgentAmount
  //                   MegaAgentAmount
  //                   SuperAgentAmount
  //               }
  //             }`,
  //     });
  //   }
  //   var config = {
  //     method: "post",
  //     url: "https://api.studio.thegraph.com/query/51007/dop-sale/version/latest",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       setTransactions(response?.data?.data?.investments);
  //     })
  //     .catch(function (error) { });
  // };

  // const getTotalList = async () => {
  //   if (user?.role === "admin") {
  //     var data = null;
  //     data = JSON.stringify({
  //       query: `query MyQuery {
  //                   investments (orderBy: blockTimestamp
  //                       orderDirection: desc
  //                       first: 10) {
  //                       tokenSelected
  //                       by
  //                       blockTimestamp
  //                       code_AgentAddress
  //                       code_AgentPercentage
  //                       code_GodAgentAddress
  //                       code_GodAgentPercentage
  //                       code_SuperAgentAddress
  //                       code_SuperAgentPercentage
  //                   dopPurchased
  //                       code_code
  //                       id
  //                       index
  //                       price
  //                       round
  //                       transactionHash
  //                       AgentAmount
  //                       amountInvested
  //                       GodAgentAmount
  //                       MegaAgentAmount
  //                       SuperAgentAmount
  //                   }
  //                 }`,
  //       variables: {},
  //     });
  //     var config = {
  //       method: "post",
  //       url: "https://api.studio.thegraph.com/query/51007/dop-sale/version/latest",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };
  //     await axios(config)
  //       .then(async function (response) {
  //         if (response?.data?.data?.investments?.length > 0) {
  //           setTransactions(response?.data?.data?.investments);
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error, "error");
  //       });
  //   } else {
  //     var data = null;
  //     if (user?.role === "agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               first: 10
  //               where: { code_AgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "super_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               first: 10
  //               where: { code_SuperAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "mega_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               first: 10
  //               where: { code_MegaAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "god_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(
  //               orderBy: blockTimestamp
  //               orderDirection: desc
  //               first: 10
  //               where: { code_GodAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     }
  //     var config = {
  //       method: "post",
  //       url: "https://api.studio.thegraph.com/query/51007/dop-sale/version/latest",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };
  //     await axios(config)
  //       .then(async function (response) {
  //         if (response?.data?.data?.investments?.length > 0) {
  //           setTransactions(response?.data?.data?.investments);
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error, "error");
  //       });
  //   }
  // };

  // const getTotalList2 = async () => {
  //   if (user?.role === "admin") {
  //     var data = null;
  //     data = JSON.stringify({
  //       query: `query MyQuery {
  //                   investments {
  //                       tokenSelected
  //                       by
  //                       blockTimestamp
  //                       code_AgentAddress
  //                       code_AgentPercentage
  //                       code_GodAgentAddress
  //                       code_GodAgentPercentage
  //                       code_SuperAgentAddress
  //                       code_SuperAgentPercentage
  //                       dopPurchased
  //                       code_code
  //                       id
  //                       index
  //                       price
  //                       round
  //                       transactionHash
  //                       AgentAmount
  //                       amountInvested
  //                       GodAgentAmount
  //                       MegaAgentAmount
  //                       SuperAgentAmount
  //                   }
  //                 }`,
  //       variables: {},
  //     });
  //     var config = {
  //       method: "post",
  //       url: "https://api.studio.thegraph.com/query/51007/dop-sale/version/latest",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };
  //     await axios(config)
  //       .then(async function (response) {
  //         if (response?.data?.data?.investments?.length > 0) {
  //           setPageCount(response?.data?.data?.investments?.length / 10);
  //           setData(response?.data?.data?.investments);
  //           setTotal(response?.data?.data?.investments?.length);
  //           // setUserObj(response?.data?.data?.investments[0]);
  //           let amountT = 0;
  //           let percUs = 0;
  //           let percEth = 0;
  //           let amoUs = 0;
  //           let amoEth = 0;
  //           let sumT = 0;
  //           let dop = 0;
  //           let dopUs = 0;
  //           for (let i of response?.data?.data?.investments) {
  //             if (i?.tokenSelected === "ETH") {
  //               amoEth =
  //                 parseInt(i?.amountInvested) / 1000000000000000000 + amoEth;
  //               dop =
  //                 (usdPrice *
  //                   (parseInt(i?.amountInvested) / 1000000000000000000)) /
  //                 (parseInt(i?.price) / 1000000000000000000) +
  //                 dop;
  //               // amoEth = (((usdPrice * (parseInt(i?.amountInvested) / 1000000000000000000)))) + amoEth;
  //             } else {
  //               amoUs = parseInt(i?.amountInvested) / 1000000 + amoUs;
  //               dopUs =
  //                 parseInt(i?.amountInvested) /
  //                 1000000 /
  //                 (parseInt(i?.price) / 1000000000000000000) +
  //                 dopUs;
  //             }
  //           }
  //           setPurchases({
  //             eth: amoEth,
  //             us: amoUs,
  //           });
  //           setDops({
  //             eth: dop,
  //             us: dopUs,
  //           });
  //           setDopPrice(
  //             parseInt(response?.data?.data?.investments[0]?.price) /
  //             1000000000000000000
  //           );
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log(error, "error");
  //       });
  //   } else {
  //     var data = null;
  //     if (user?.role === "agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               where: { code_AgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "super_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               where: { code_SuperAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "mega_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               where: { code_MegaAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     } else if (user?.role === "god_agent") {
  //       data = JSON.stringify({
  //         query: `query MyQuery {
  //             investments(orderBy: blockTimestamp
  //               orderDirection: desc
  //               where: { code_GodAgentAddress_contains: "${user?.walletAddress}"}) {
  //               tokenSelected
  //               by
  //               blockTimestamp
  //               code_AgentAddress
  //               code_AgentPercentage
  //               code_GodAgentAddress
  //               code_GodAgentPercentage
  //               code_SuperAgentAddress
  //               code_SuperAgentPercentage
  //               dopPurchased
  //               code_code
  //               id
  //               index
  //               price
  //               round
  //               transactionHash
  //               AgentAmount
  //               amountInvested
  //               GodAgentAmount
  //               MegaAgentAmount
  //               SuperAgentAmount
  //             }
  //           }`,
  //         variables: {},
  //       });
  //     }
  //     var config = {
  //       method: "post",
  //       url: "https://api.studio.thegraph.com/query/51007/dop-sale/version/latest",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };
  //     await axios(config)
  //       .then(async function (response) {
  //         setPageCount(response?.data?.data?.investments?.length / 10);
  //         setData(response?.data?.data?.investments);
  //         setTotal(response?.data?.data?.investments?.length);
  //         // setUserObj(response?.data?.data?.investments[0]);
  //         let amountT = 0;
  //         let percUs = 0;
  //         let percEth = 0;
  //         let amoUs = 0;
  //         let amoEth = 0;
  //         let sumT = 0;
  //         let dop = 0;
  //         let dopUs = 0;
  //         for (let i of response?.data?.data?.investments) {
  //           if (i?.tokenSelected === "ETH") {
  //             amoEth =
  //               parseInt(i?.amountInvested) / 1000000000000000000 + amoEth;
  //             dop =
  //               (usdPrice *
  //                 (parseInt(i?.amountInvested) / 1000000000000000000)) /
  //               (parseInt(i?.price) / 1000000000000000000) +
  //               dop;
  //             // amoEth = (((usdPrice * (parseInt(i?.amountInvested) / 1000000000000000000)))) + amoEth;
  //           } else {
  //             amoUs = parseInt(i?.amountInvested) / 1000000 + amoUs;
  //             dopUs =
  //               parseInt(i?.amountInvested) /
  //               1000000 /
  //               (parseInt(i?.price) / 1000000000000000000) +
  //               dopUs;
  //           }
  //         }
  //         setPurchases({
  //           eth: amoEth,
  //           us: amoUs,
  //         });
  //         setDops({
  //           eth: dop,
  //           us: dopUs,
  //         });
  //         setDopPrice(
  //           parseInt(response?.data?.data?.investments[0]?.price) /
  //           1000000000000000000
  //         );
  //       })
  //       .catch(function (error) {
  //         console.log(error, "error");
  //       });
  //   }
  // };

  // const GetAllRounds = async () => {
  //   // if (account) {
  //   var config = {
  //     method: "get",
  //     url: `${Api_Url}/users/leader-counts`,
  //     headers: {
  //       authorization: `Bearer ` + tok,
  //     },
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       // console.log("asdasdasdasdasd", response?.data?.data?.stats[0]);
  //       if (response?.data?.data?.stats?.length > 0) {
  //         setadminobj(response?.data?.data?.stats[0]);
  //       }
  //       // setAllRounds(response.data.data.saleRounds)
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       // setLoader(false);
  //     });
  //   // }
  // };

  useEffect(() => {
    if (user?.role == "admin") {
      GetAllRounds();
    } else {
      GetMyData();
    }
  }, [usdPrice, user]);

  // const GetSearch = async (e) => {
  //   e?.preventDefault();
  //   if (search !== "") {
  //     getTotalListSearch();
  //   } else {
  //     getTotalList();
  //     getTotalList2();
  //   }
  // };

  // const getTotalListSearch = async (eve) => {
  //   let dumArr = [];
  //   let url = null;
  //   if (eve) {
  //     if (search !== "") {
  //       url = `${Api_Url}/users/access-codes?search=${search?.toLowerCase()}&&leaderType=${eve}`;
  //     } else {
  //       url = `${Api_Url}/users/access-codes?leaderType=${eve}`;
  //     }
  //   } else {
  //     if (search !== "") {
  //       url = `${Api_Url}/users/access-codes?search=${search?.toLowerCase()}`;
  //     }
  //   }
  //   if (url) {
  //     var config = {
  //       method: "get",
  //       url: url,
  //       headers: {
  //         authorization: `Bearer ` + tok,
  //       },
  //     };
  //     axios(config)
  //       .then(function async(response) {
  //         for (let i of response?.data?.data) {
  //           dumArr?.push(i.accessCode);
  //         }
  //         if (dumArr?.length > 0) {
  //           setCode(dumArr);
  //           var data = JSON.stringify({
  //             query: `query MyQuery {
  //                         investments(
  //                           where: {code_code_in: ${JSON.stringify(dumArr)}}
  //                           orderBy: blockTimestamp, orderDirection: desc) {
  //                           tokenSelected
  //                           by
  //                           blockTimestamp
  //                           code_AgentAddress
  //                           code_AgentPercentage
  //                           code_GodAgentAddress
  //                           code_GodAgentPercentage
  //                           code_SuperAgentAddress
  //                           code_SuperAgentPercentage
  //                           dopPurchased
  //                           code_code
  //                           id
  //                           index
  //                           price
  //                           round
  //                           transactionHash
  //                           AgentAmount
  //                           amountInvested
  //                           GodAgentAmount
  //                           MegaAgentAmount
  //                           SuperAgentAmount
  //                         }
  //                       }`,
  //             variables: {},
  //           });
  //           var config = {
  //             method: "post",
  //             url: "https://api.studio.thegraph.com/query/51007/dop-sale/version/latest",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             data: data,
  //           };
  //           axios(config)
  //             .then((response) => {
  //               if (response?.data?.data?.investments?.length > 0) {
  //                 setPageCount(response?.data?.data?.investments?.length / 10);
  //                 setData(response?.data?.data?.investments);
  //                 setTotal(response?.data?.data?.investments?.length);
  //                 setTransactions(
  //                   response?.data?.data?.investments?.slice(0, 10)
  //                 );
  //                 // setUserObj(response?.data?.data?.investments[0]);
  //                 let amountT = 0;
  //                 let percUs = 0;
  //                 let percEth = 0;
  //                 let amoUs = 0;
  //                 let amoEth = 0;
  //                 let sumT = 0;
  //                 let dop = 0;
  //                 let dopUs = 0;
  //                 for (let i of response?.data?.data?.investments) {
  //                   if (i?.tokenSelected === "ETH") {
  //                     amoEth =
  //                       parseInt(i?.amountInvested) / 1000000000000000000 +
  //                       amoEth;
  //                     dop =
  //                       (usdPrice *
  //                         (parseInt(i?.amountInvested) / 1000000000000000000)) /
  //                       (parseInt(i?.price) / 1000000000000000000) +
  //                       dop;
  //                     // amoEth = (((usdPrice * (parseInt(i?.amountInvested) / 1000000000000000000)))) + amoEth;
  //                   } else {
  //                     amoUs = parseInt(i?.amountInvested) / 1000000 + amoUs;
  //                     dopUs =
  //                       parseInt(i?.amountInvested) /
  //                       1000000 /
  //                       (parseInt(i?.price) / 1000000000000000000) +
  //                       dopUs;
  //                   }
  //                 }
  //                 setPurchases({
  //                   eth: amoEth,
  //                   us: amoUs,
  //                 });
  //                 setDops({
  //                   eth: dop,
  //                   us: dopUs,
  //                 });
  //                 setDopPrice(
  //                   parseInt(response?.data?.data?.investments[0]?.price) /
  //                   1000000000000000000
  //                 );
  //               } else {
  //                 setTransactions([]);
  //                 setData([]);
  //               }
  //             })
  //             .catch((err) => {
  //               setTransactions([]);
  //               setData([]);
  //               console.log(err);
  //             });
  //         } else {
  //           setData([]);
  //           setTransactions([]);
  //         }
  //       })
  //       .catch((err) => { });
  //   }
  //   return 0;
  // };

  // const GetFilterRole = (eve) => {
  //   // getTotalListSearch(eve);
  // };

  // console.log("pageCount", pageCount)

  const functiontogetback = () => {
    localStorage.setItem("indexvalue", 0);
    setindexwait(0);
  };

  // const setSearching = (e) => {
  //   if (e === "") {
  //     GetSearch();
  //   } else {
  //     setSearch(e);
  //   }
  // };

  const GetAllRounds = async () => {
    // if (account) {
    var config = {
      method: "get",
      url: `${Api_Url}/users/admin/stats?privateSale=GEMS`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        let value = response?.data?.data;
        // console.log("value: ", value);
        setTokenNftStats(value);
        if (response?.data?.data?.stats?.length > 0) {
          setadminobj(response?.data?.data?.stats[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // }
  };

  const GetMyData = async () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/my/stats?privateSale=GEMS`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        let value = response?.data?.data;
        // console.log("sdfsfsdfsdfsdfdsfsdfdf", value);
        setPDP(value?.purchasesAndDopPurchased);
        setPEU(value?.purchasedAndEarningsUsdt);
        setPEE(value?.purchasedAndEarningsEth);
        setPET(value?.purchasedAndEarningsTomi);
        setIND(value?.inDirectSales);
        setDT(value?.directSales);
        setPC(value?.myPurchasesChange);
        setPMC(value?.myPurchasesAmountChange);
        setLC(value?.myLeadersChange);
        setDPC(value?.myDopPurchasedChange);
        setLEC(value?.myEarningsChange);
        setgg(value?.leaders);
        setmm(value?.megas);
        setss(value?.supers);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // history.push("/login");
        }
        console.log(error);
      });
    // }
  };

  return (
    <>
      <section className="alltransaction">
        <div className="custom-container">
          <button
            class="btn-back guvxgyvxvtxx"
            onClick={() => functiontogetback()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                fill="white"
              ></path>
              <path
                d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                fill="white"
              ></path>
            </svg>{" "}
            Back
          </button>
          <div className="main-heading">
            <h6>All Transactions</h6>
            {user?.role == "god_agent" && user?.specialAccess?.length > 0 ? (
              <div className="set-twice-text">
                <div className="parent-toggle-switch-custom">
                  <div class="custom-toggle">
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="checkbox"
                      checked={myTree}
                      onChange={(e) => {
                        setMyTree(e.target.checked);
                      }}
                    />
                    <label class="switch" for="checkbox">
                      <span class="slider"></span>
                    </label>
                  </div>
                </div>
                <h6>Show data from my tree only</h6>
              </div>
            ) : null}
          </div>
          {user?.role == "admin" ? (
            <div className="bottom-cards">
              <div className="single-card">
                <img
                  src="\assets\dashboard\noofpurchases.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Number of Purchases</p>
                <h6>{adminobj?.TotalPurchases}</h6>
              </div>
              <div className="single-card">
                <img src="\gemslogo.svg" alt="img" className="gems-logo-img" />
                <p>GEMS Purchased</p>
                {adminobj?.totalDopPurchased ? (
                  <h6>
                    {parseInt(
                      (
                        tokenNftStats.stats[0]?.totalDopPurchased +
                        tokenNftStats.nftStats[0]?.totalDopPurchased
                      ).toFixed(2)
                    ).toLocaleString("en-US")}{" "}
                    &nbsp;GEMS
                  </h6>
                ) : (
                  <h6>0.00 GEMS</h6>
                )}
                <div className="bottom-text">
                  <p className="uni-text">
                    <span></span>
                    {tokenNftStats
                      ? parseFloat(
                          tokenNftStats?.stats[0]?.totalDopPurchased?.toFixed(4)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    Token
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {tokenNftStats
                      ? parseInt(
                          tokenNftStats?.nftStats[0]?.totalDopPurchased?.toFixed(
                            2
                          )
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    NFT
                  </p>
                </div>
                {/* <div className="bottom-text">
              <p className="uni-text">
                <span></span>~
                {dops ? ((dops?.eth + dops?.us) * dopPrice)?.toFixed(2) : 0}{" "}
                USD
              </p>
            </div> */}
              </div>
              <div className="single-card">
                <img
                  src="\assets\dashboard\purchasedamount.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Purchased Amount</p>
                {usdPrice && adminobj ? (
                  <h6 className="datahead">
                    ~$
                    {parseInt(
                      (
                        usdPrice * adminobj?.totalPurchaseEth +
                        adminobj?.totalPurchaseUsdt +
                        tomiPrice * adminobj?.totalPurchaseTomi +
                        adminobj?.totalPurchaseWbtc +
                        adminobj?.totalPurchaseUsdc
                      )?.toFixed(2)
                    ).toLocaleString("en-US")}{" "}
                  </h6>
                ) : (
                  <h6 className="datahead">~$0.00 USD</h6>
                )}
                <div className="bottom-text">
                  <p className="uni-text">
                    <span></span>
                    {adminobj ? adminobj?.totalPurchaseEth?.toFixed(4) : 0} ETH
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseUsdt?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    USDT
                  </p>
                  {/* <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseTomi?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    TOMI
                  </p> */}
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseWbtc?.toFixed(5)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    WBTC
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {adminobj
                      ? parseFloat(
                          adminobj?.totalPurchaseUsdc?.toFixed(2)
                        )?.toLocaleString("en-US")
                      : 0}{" "}
                    USDC
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="bottom-cards">
              <div className="single-card">
                <img
                  src="\assets\dashboard\noofpurchases.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Number of Purchases</p>
                {pdp?.length > 0 ? (
                  <h6>
                    {pdp[0]?.TotalPurchases > 0 ? pdp[0]?.TotalPurchases : 0}{" "}
                    <span className={pc > 0 ? "green-text" : "red-text"}>
                      {pc ? pc.toFixed(1) + "% " : ""}
                    </span>
                  </h6>
                ) : (
                  <h6>0</h6>
                )}
              </div>
              <div className="single-card">
                <img src="\gemslogo.svg" alt="img" className="gems-logo-img" />
                <p>GEMS Purchased</p>
                {usdPrice && pdp ? (
                  <h6>
                    {pdp[0]?.totalDopPurchased > 0
                      ? parseFloat(pdp[0]?.totalDopPurchased)
                          .toFixed(2)
                          ?.toLocaleString("en-US")
                      : 0}
                    &nbsp;GEMS{" "}
                    <span className={dpc > 0 ? "green-text" : "red-text"}>
                      {dpc ? dpc.toFixed(1) + "% " : ""}
                    </span>
                  </h6>
                ) : (
                  <h6>0.00 GEMS</h6>
                )}
                <div className="bottom-text">
                  <p className="uni-text">
                    <span></span>
                    {dt[0]?.totalDirectDop
                      ? dt[0]?.totalDirectDop?.toFixed(4)
                      : 0}
                  </p>
                  <p className="uni-text">
                    <span></span>~
                    {ind[0]?.totalInDirectDop
                      ? ind[0]?.totalInDirectDop?.toFixed(4)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="single-card">
                <img
                  src="\assets\dashboard\purchasedamount.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Purchased Amount</p>
                {(usdPrice && pee) || peu || (tomiPrice && pet) ? (
                  <h6 className="datahead">
                    {pee?.length > 0
                      ? "~$" +
                        parseFloat(
                          (
                            usdPrice *
                              (pee[0]?.totalPurchaseEth
                                ? pee[0]?.totalPurchaseEth
                                : 0) +
                            (peu[0]?.totalPurchaseUsdt
                              ? peu[0]?.totalPurchaseUsdt
                              : 0) +
                            tomiPrice *
                              (pet[0]?.totalPurchaseTomi
                                ? pet[0]?.totalPurchaseTomi
                                : 0)
                          )?.toFixed(4)
                        ).toLocaleString("en-US")
                      : 0}{" "}
                    <span className={pmc > 0 ? "green-text" : "red-text"}>
                      {pmc ? pmc.toFixed(1) + "% " : ""}
                    </span>
                  </h6>
                ) : (
                  <h6 className="datahead">~$0.00 USD</h6>
                )}
                {pee?.length > 0 ? (
                  <div className="bottom-text">
                    <p className="uni-text">
                      <span></span>
                      {pee[0]?.totalPurchaseEth
                        ? pee[0]?.totalPurchaseEth?.toFixed(4)
                        : 0}{" "}
                      ETH
                    </p>
                    <p className="uni-text">
                      <span></span>
                      {peu[0]?.totalPurchaseUsdt
                        ? parseFloat(
                            peu[0]?.totalPurchaseUsdt?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      USDT
                    </p>
                    {/* <p className="uni-text">
                      <span></span>
                      {pet[0]?.totalPurchaseTomi
                        ? parseFloat(
                            pet[0]?.totalPurchaseTomi?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      TOMI
                    </p> */}
                    <p className="uni-text">
                      <span></span>
                      {pet[0]?.totalPurchaseWbtc
                        ? parseFloat(
                            pet[0]?.totalPurchaseWbtc?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      WBTC
                    </p>
                    <p className="uni-text">
                      <span></span>
                      {pet[0]?.totalPurchaseUsdc
                        ? parseFloat(
                            pet[0]?.totalPurchaseUsdc?.toFixed(2)
                          )?.toLocaleString("en-US")
                        : 0}{" "}
                      USDC
                    </p>
                  </div>
                ) : (
                  <div className="bottom-text">
                    <p className="uni-text">
                      <span></span>0 ETH
                    </p>
                    <p className="uni-text">
                      <span></span>0 USDT
                    </p>
                    <p className="uni-text">{/* <span></span>0 TOMI */}</p>
                    <p className="uni-text">
                      <span></span>0 WBTC
                    </p>
                    <p className="uni-text">
                      <span></span>0 USDC
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="main-heading111">
            <div className="rightside-content">
              <div
                className={
                  userObj?.role == "mini_agent"
                    ? "custom-option-field w-100"
                    : "custom-option-field"
                }
              >
                {/* <form onSubmit={(e) => GetSearch(e)}> */}
                <input
                  onChange={(e) => {
                    if (page != 0) {
                      setPage(0);
                    }
                    setSearch(e.target.value);
                  }}
                  type="search"
                  placeholder="Search..."
                />
                {/* </form> */}
                <img
                  style={{ cursor: "pointer" }}
                  src="\assets\search-icon.svg"
                  alt="img"
                  className="img-fluid search-icon"
                  // onClick={() => GetSearch()}
                />
                <div class="dropdown transaction-inner-drop">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {searchBy == "name"
                      ? "Name"
                      : searchBy == "accessCodeFilter"
                      ? "Code"
                      : searchBy == "by"
                      ? "Wallet"
                      : ""}
                    <img
                      src="\assets\arrow-down.svg"
                      alt="img"
                      className="img-fluid ms-1"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={(e) => setSearchBy("name")}
                      >
                        By Name
                      </a>
                    </li>

                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={(e) => setSearchBy("accessCodeFilter")}
                      >
                        By Code
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={(e) => setSearchBy("by")}
                      >
                        By Wallet
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {userObj?.role == "mini_agent" ? (
                ""
              ) : (
                <div class="dropdown">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div>
                      Type{" "}
                      <img
                        src="\assets\arrow-down.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </button>
                  {userObj?.role == "admin" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("god_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          God Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mega_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mega Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => setTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  ) : userObj?.role == "god_agent" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mega_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mega Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => setTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  ) : userObj?.role == "mega_agent" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => setTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  ) : userObj?.role == "super_agent" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  ) : userObj?.role == "agent" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {/* <button className="reset-btn-global">Reset</button> */}
            </div>
          </div>
          {transactionApiCalled ? (
            <>
              {transactions?.length > 0 ? (
                <DataTable
                  page={page}
                  handlePageClick={handlePageChange}
                  setSearch={setSearch}
                  search={search}
                  usdPrice={usdPrice}
                  transactions={transactions}
                  // setTransactions={setTransactions}
                  pageCount={pageCount}
                  user={user}
                  setOrderDirection={setOrderDirection}
                  setOrderField={setOrderField}
                />
              ) : (
                <p
                  className=""
                  style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
                >
                  No data found!
                </p>
              )}
            </>
          ) : (
            <p
              className=""
              style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
            >
              Loading...
            </p>
          )}

          <div className="footer-content mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M10 13L5 8L10 3"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M5 13L10 8L5 3"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page}
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllTransaction;
