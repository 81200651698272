import React, { useState, useEffect } from "react";
import "./allagents.scss";
import axios from "axios";
import DataTable from "./DataTable";
import DataTableLeaders from "./DataTableLeaders/DataTableLeaders";
import DataTableSpecialAccess from "./DataTableSpecialAccess/DataTableSpecialAccess";
import { useHistory } from "react-router-dom";

import ReactPaginate from "react-paginate";
import moment from "moment";
import { Api_Url } from "../../../utils/ApiUrl";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Specialaccess from "./Specialaccess";

const ViewDetail = ({
  setRoutes,
  user,
  userDel,
  setUserDel,
  setShow,
  setBlockUnblock,
  blockUnblock,
  tomiPrice,
  usdPrice,
  btcPrice,
}) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  console.log("asdasdasdasdasdasdasdasdasdasd", userDel);
  const [transactions, setTransactions] = useState([]);
  const [specialAccessData, setSpecialAccessData] = useState([]);

  const [purchases, setPurchases] = useState(null);
  const [leaderscount, setleaderscount] = useState(null);
  const [accessCodeToggle, setAccessCodeToggle] = useState(true);

  const [dops, setDops] = useState(null);
  const [search, setSearch] = useState("");
  let tok = localStorage.getItem("accessToken");

  const [dopPrice, setDopPrice] = useState(null);
  // const [usdPrice, setUsdPrice] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCountAgent, setPageCountAgent] = useState(0);

  const [earnings, setEarnings] = useState(null);

  const [agents, setAgents] = useState([]);

  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [code, setCode] = useState([]);
  const [page1, setPage1] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [roleTypeFilter, setRoleTypeFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryNames, setSearchQueryNames] = useState("");

  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");

  const [orderFieldAgents, setOrderFieldAgents] = useState("createdAt");
  const [orderDirectionAgents, setOrderDirectionAgents] = useState("-1");
  const [tabKey, setTabKey] = useState("transactions");

  const [specialAccess, setSpecialAccess] = useState([]);
  const [addSpecialAccess, setAddSpecialAccess] = useState([]);

  const [removeSpecialAccess, setRemoveSpecialAccess] = useState([]);

  const [godLeadersList, setGodLeadersList] = useState([]);
  const [minimumBuy, setMinimumBuy] = useState("");

  const [newWalletAddress, setNewWalletAddress] = useState("");

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const [show123, setShow123] = useState(false);
  const handleClose123 = () => setShow123(false);

  useEffect(() => {
    getLeaderNames();
  }, []);

  // useEffect(() => {
  //   if (userDel) {
  //     getDashboardData();
  //   }
  // }, [userDel]);

  const changeMinimumBuy = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/buying-limit`,

      headers: {
        Authorization: "Bearer " + val,
      },
      data: {
        leaderRole: userDel?.role,
        minimumBuy: minimumBuy,
      },
    };
    axios(config)
      .then(function (response) {
        getData();
        handleClose123();
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const specialAccessList = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/god-leaders?limit=1000`,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        setGodLeadersList(response?.data?.data);
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const editSpecialAccessList = () => {
    setLoader(true);
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/god-leader`,
      data: {
        role: userDel?.role,
        addSpecialAccess: addSpecialAccess,
        removeSpecialAccess: removeSpecialAccess,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        getData();
        handleClose1();
        setAddSpecialAccess([]);
        setRemoveSpecialAccess([]);
        setLoader(false);
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        setLoader(false);
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const getDashboardData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/leaders?limit=15&offset=1&&role=${userDel?.role}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    if (search) {
      dynamicQuery += `&by=${search}`;
    }

    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const toggleAccessCode = async () => {
    var config = {
      method: "patch",
      url: `${Api_Url}/users/${userDel?._id}/toggle-access-code`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    await axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (user && tabKey == "leaders") {
      GetAgents();
    } else if (user && tabKey == "special") {
      getSpecialAccessData();
    }
  }, [
    user,
    searchQuery,
    leaderFilter,
    roleTypeFilter,
    orderDirectionAgents,
    orderFieldAgents,
    tabKey,
    page,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage1(selectedPage);
  };

  const getTransactionsData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/all-transactions?limit=10&offset=${
        page1 + 1
      }&privateSale=GEMS`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    if (search) {
      dynamicQuery += `&by=${search}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }

    if (roleTypeFilter) {
      dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    }
    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        // console.log("trx data: ", res?.data?.data?.transactions);
        setTransactions(res?.data?.data?.transactions);
        setPageCount(res?.data?.data?.pages);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const getLeaderNames = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/leaders-names?limit=${500}&role=${
        userDel?.role
      }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    let dynamicQuery = config.url;

    if (searchQueryNames) {
      dynamicQuery += `&name=${encodeURIComponent(searchQueryNames)}`;
    }
    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const setSearchQuerying = (e) => {
    setSearchQuery(e);
    if (e === "") {
      GetAgents();
    }
  };

  const handleAgentsPageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const GetAgents = () => {
    // off?.preventDefault();
    const val = localStorage.getItem("accessToken");
    let valu = null;

    var config = {
      method: "get",
      url: `${Api_Url}/users/${
        userDel?._id
      }/leaders?limit=${10}&privateSale=GEMS&offset=${page + 1}&role=${
        userDel?.role
      }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    let dynamicQuery = config.url;

    if (leaderFilter) {
      dynamicQuery += `&name=${encodeURIComponent(leaderFilter)}`;
    }

    if (roleTypeFilter) {
      dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    }
    if (searchQuery) {
      dynamicQuery += `&search=${encodeURIComponent(searchQuery)}`;
    }
    if (orderFieldAgents) {
      dynamicQuery += `&orderField=${orderFieldAgents}&orderDirection=${orderDirectionAgents}`;
    }
    config.url = dynamicQuery;

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setCount(response.data.data.count);
        setAgents(resData?.agents);
        let arr = Array.from(Array(parseInt(response.data.data.pages)).keys());
        setPageCountAgent(response?.data?.data?.pages);

        setPages(arr);
        // setSearch('')

        setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
        setLoader(false);
      });
  };

  const getSpecialAccessData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}/special-accesses?limit=10&offset=${
        page1 + 1
      }&role=${userDel?.role}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    // if (search) {
    //   dynamicQuery += `&by=${search}`;
    // }
    // if (orderField) {
    //   dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    // }

    // if (roleTypeFilter) {
    //   dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    // }
    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        // console.log("trx data: ", res?.data?.data?.transactions);
        setSpecialAccessData(res?.data?.data?.specialAccesses);
        setPageCount(res?.data?.data?.pages);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const getPrevData = (off) => {
    let offset = parseInt(off) - 1;
    const val = localStorage.getItem("accessToken");
    if (offset > 0) {
      // setLoader(true);
      var config = null;
      config = {
        method: "get",
        url: `${Api_Url}/users/${userDel?._id}/leaders?offset=${offset}&&limit=10&&orderField=createdAt&&orderDirection=-1&role=${userDel?.role}`,
        headers: {
          Authorization: "Bearer " + val,
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          const resData = response?.data?.data;
          let arr = Array.from(
            Array(parseInt(response.data.data.pages)).keys()
          );
          setPages(arr);
          setAgents(resData?.agents);
          if (currentPage - 1 >= 0) {
            setCurrentPage(currentPage - 1);
          }
          if (off >= 0) {
            if (offset - 1 == 0) {
              setLimit(1);
            } else {
              setLimit((offset - 1) * 5);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // setLoader(false);
    }
  };

  const getNextData = (off) => {
    let offset = parseInt(off) + 1;
    const val = localStorage.getItem("accessToken");
    if (pages.length > off) {
      if (off < agents.length) {
        var config = null;
        config = {
          method: "get",
          url: `${Api_Url}/users/${userDel?._id}/leaders?offset=${offset}&&limit=10&&orderField=createdAt&&orderDirection=-1&role=${userDel?.role}`,
          headers: {
            Authorization: "Bearer " + val,
            "Content-Type": "application/json",
          },
        };

        axios(config)
          .then(function (response) {
            const resData = response?.data?.data;
            let arr = Array.from(
              Array(parseInt(response.data.data.pages)).keys()
            );
            setPages(arr);
            setAgents(resData?.agents);
            if (off <= agents.length) {
              setCurrentPage(offset);
              setLimit(off * 5);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  // useEffect(() => {
  //   axios
  //     .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
  //     .then((res) => {
  //       setUsdPrice(res?.data?.USD);
  //       // setEthUsd(res.data);
  //     });
  // }, []);

  // console.log("items id", userDel)
  const getData = async () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${userDel?._id}?privateSale=GEMS`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("user coount eth", response);
        const resData = response?.data?.data;
        setleaderscount(resData);
        setMinimumBuy(resData?.minimumBuy);
        setAccessCodeToggle(resData?.accessCodeEnabled);
        setSpecialAccess(resData?.specialAccess);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (userDel) {
      getData();
    }
  }, [userDel]);

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (usdPrice && tabKey == "transactions") {
      getTransactionsData();
    }
  }, [
    usdPrice,
    userDel,
    search,
    orderDirection,
    orderField,
    tabKey,
    page1,
    roleTypeFilter,
  ]);

  // console.log("abbbbbbbbbbbbbbbbbbbbbbb", userDel?.role)
  const [showchange, setShowchange] = useState(false);

  const handleClosechange = () => setShowchange(false);
  const handleShowchange = () => setShowchange(true);

  const [showconfirmation, setShowconfirmation] = useState(false);

  const handleCloseconfirmation = () => setShowconfirmation(false);
  const handleShowconfirmation = () => setShowconfirmation(true);

  const [showchangesuccess, setShowchangesuccess] = useState(false);

  const handleClosechangesuccess = () => setShowchangesuccess(false);
  const handleShowchangesuccess = () => setShowchangesuccess(true);
  return (
    <>
      <section className="viewdetail">
        <div className="custom-container veiwdetaileinnerpage">
          <div className="main-heading">
            <button
              onClick={() => {
                setRoutes(false);
                setUserDel(null);
              }}
              className="btn-back"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                  fill="white"
                />
                <path
                  d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                  fill="white"
                />
              </svg>{" "}
              Back
            </button>
            <div className="twice-btn">
              {user?.role == "admin" ? (
                <>
                  <div className="set-custom-claim-text">
                    <div className="parent-toggle-switch-custom">
                      <div class="custom-toggle">
                        <input
                          type="checkbox"
                          class="checkbox"
                          id="checkbox"
                          checked={!accessCodeToggle}
                          onChange={(e) => {
                            toggleAccessCode();
                            setAccessCodeToggle(!accessCodeToggle);
                          }}
                        />
                        <label class="switch" for="checkbox">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <h6>Disable this Code</h6>
                  </div>
                  {leaderscount?.role == "god_agent" ? (
                    <button
                      onClick={() => {
                        specialAccessList();
                        setShow1(true);
                      }}
                      className="btn-edit"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M17.5 18.8333H2.5C2.15833 18.8333 1.875 18.55 1.875 18.2083C1.875 17.8666 2.15833 17.5833 2.5 17.5833H17.5C17.8417 17.5833 18.125 17.8666 18.125 18.2083C18.125 18.55 17.8417 18.8333 17.5 18.8333Z"
                          fill="white"
                        />
                        <path
                          d="M15.85 3.39999C14.2333 1.78332 12.65 1.74166 10.9917 3.39999L9.98333 4.40832C9.9 4.49166 9.86666 4.62499 9.9 4.74166C10.5333 6.94999 12.3 8.71666 14.5083 9.34999C14.5417 9.35832 14.575 9.36666 14.6083 9.36666C14.7 9.36666 14.7833 9.33332 14.85 9.26666L15.85 8.25832C16.675 7.44166 17.075 6.64999 17.075 5.84999C17.0833 5.02499 16.6833 4.22499 15.85 3.39999Z"
                          fill="white"
                        />
                        <path
                          d="M13.0083 10.1084C12.7667 9.99169 12.5333 9.87502 12.3083 9.74169C12.125 9.63335 11.95 9.51669 11.775 9.39169C11.6333 9.30002 11.4667 9.16669 11.3083 9.03335C11.2917 9.02502 11.2333 8.97502 11.1667 8.90835C10.8917 8.67502 10.5833 8.37502 10.3083 8.04169C10.2833 8.02502 10.2417 7.96669 10.1833 7.89169C10.1 7.79169 9.95833 7.62502 9.83333 7.43335C9.73333 7.30835 9.61666 7.12502 9.50833 6.94169C9.375 6.71669 9.25833 6.49169 9.14166 6.25835C8.9887 5.93057 8.55849 5.8332 8.30271 6.08897L3.61666 10.775C3.50833 10.8834 3.40833 11.0917 3.38333 11.2334L2.93333 14.425C2.85 14.9917 3.00833 15.525 3.35833 15.8834C3.65833 16.175 4.075 16.3334 4.525 16.3334C4.625 16.3334 4.725 16.325 4.825 16.3084L8.025 15.8584C8.175 15.8334 8.38333 15.7334 8.48333 15.625L13.1771 10.9312C13.4278 10.6806 13.3336 10.2493 13.0083 10.1084Z"
                          fill="white"
                        />
                      </svg>
                      Edit Special Access
                    </button>
                  ) : null}
                  <button
                    onClick={() => setShow(true)}
                    className={blockUnblock ? "btn-del btnunblock" : "btn-del"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M9.99999 10.9167C12.5313 10.9167 14.5833 8.86464 14.5833 6.33333C14.5833 3.80203 12.5313 1.75 9.99999 1.75C7.46868 1.75 5.41666 3.80203 5.41666 6.33333C5.41666 8.86464 7.46868 10.9167 9.99999 10.9167Z"
                        fill="#FE0000"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.7108 18.8434C11.5441 18.5197 11.4575 18.1608 11.4583 17.7967V14.88C11.4583 14.1684 11.7825 13.5325 12.2917 13.1125V12.7967C12.2917 12.4625 12.345 12.1334 12.4475 11.8217C11.6377 11.6965 10.8194 11.6341 10 11.635C7.23168 11.635 4.78084 12.3275 3.25918 13.365C2.09834 14.1567 1.45834 15.1625 1.45834 16.2184V17.4267C1.45878 17.8023 1.60818 18.1624 1.87376 18.4279C2.13934 18.6935 2.49942 18.8429 2.87501 18.8434H11.7108Z"
                        fill="#FE0000"
                      />
                      <path
                        d="M15.8333 11.755H15.4167C15.1407 11.7559 14.8762 11.8659 14.6811 12.0611C14.4859 12.2562 14.3759 12.5207 14.375 12.7967V14.0467C14.375 14.2124 14.3092 14.3714 14.1919 14.4886C14.0747 14.6058 13.9158 14.6717 13.75 14.6717C13.5842 14.6717 13.4253 14.6058 13.3081 14.4886C13.1908 14.3714 13.125 14.2124 13.125 14.0467V12.7967C13.1254 12.189 13.367 11.6064 13.7967 11.1767C14.2264 10.747 14.809 10.5054 15.4167 10.505H15.7433C16.0518 10.4929 16.3595 10.5432 16.648 10.6529C16.9365 10.7626 17.1999 10.9295 17.4223 11.1435C17.6447 11.3576 17.8216 11.6143 17.9424 11.8984C18.0631 12.1825 18.1252 12.488 18.125 12.7967V14.0467C18.125 14.2124 18.0592 14.3714 17.9419 14.4886C17.8247 14.6058 17.6658 14.6717 17.5 14.6717C17.3342 14.6717 17.1753 14.6058 17.0581 14.4886C16.9408 14.3714 16.875 14.2124 16.875 14.0467V12.7967C16.8746 12.5205 16.7647 12.2558 16.5694 12.0606C16.3742 11.8653 16.1095 11.7554 15.8333 11.755Z"
                        fill="#FE0000"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.9583 17.7967V14.88C18.9583 14.4933 18.8047 14.1223 18.5312 13.8488C18.2577 13.5753 17.8868 13.4217 17.5 13.4217H13.75C13.3632 13.4217 12.9923 13.5753 12.7188 13.8488C12.4453 14.1223 12.2917 14.4933 12.2917 14.88V17.7967C12.2917 18.6025 12.945 19.255 13.75 19.255H17.5C17.8868 19.255 18.2577 19.1014 18.5312 18.8279C18.8047 18.5544 18.9583 18.1835 18.9583 17.7967ZM15 15.9217V16.755C15 16.9208 15.0658 17.0798 15.183 17.197C15.3003 17.3142 15.4592 17.38 15.625 17.38C15.7908 17.38 15.9497 17.3142 16.0669 17.197C16.1841 17.0798 16.25 16.9208 16.25 16.755V15.9217C16.25 15.7559 16.1841 15.597 16.0669 15.4798C15.9497 15.3625 15.7908 15.2967 15.625 15.2967C15.4592 15.2967 15.3003 15.3625 15.183 15.4798C15.0658 15.597 15 15.7559 15 15.9217Z"
                        fill="#FE0000"
                      />
                    </svg>
                    {blockUnblock ? "Unblock" : "Block"}
                  </button>
                </>
              ) : null}
            </div>
            {/* <div class="dropdown d-none">
                            <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="\assets\more-icon.svg" alt="img" className="img-fluid" />
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#"><img src="\assets\edit.svg" alt="img" className="img-fluid" />Edit</a></li>
                                <li><a class="dropdown-item" href="#"><img src="\assets\delete.svg" alt="img" className="img-fluid" />Delete</a></li>
                            </ul>
                        </div> */}
          </div>
          <div className="inner-heading">
            <h4>
              {userDel?.name} - {convertToTitleCase(userDel?.role)} -{" "}
              <span>{leaderscount?.accessCode}</span>
            </h4>
          </div>
          <div
            className={
              leaderscount?.role == "mini_agent"
                ? "bottom-cards  newclassagent"
                : "bottom-cards "
            }
          >
            {leaderscount?.role === "mini_agent" ? (
              ""
            ) : (
              <div className="single-card">
                <img
                  src="\assets\dashboard\noofagents.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>No. of Leaders</p>
                {leaderscount?.role === "god_agent" ? (
                  <>
                    <h6>
                      {leaderscount
                        ? leaderscount?.megas +
                          leaderscount?.leaders +
                          leaderscount?.supers +
                          leaderscount?.miniLeaders
                        : 0}
                    </h6>
                    <div className="bottom-text">
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.megas : 0} Mega
                      </p>
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.supers : 0} Super
                      </p>
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.leaders : 0}{" "}
                        {leaderscount?.leaders == 1 ||
                        leaderscount?.leaders == 0
                          ? "Leader"
                          : "Leaders"}
                      </p>
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.miniLeaders : 0}{" "}
                        Ambassador
                      </p>
                    </div>
                  </>
                ) : leaderscount?.role === "mega_agent" ? (
                  <>
                    <h6>
                      {leaderscount
                        ? leaderscount?.leaders +
                          leaderscount?.supers +
                          leaderscount?.miniLeaders
                        : 0}
                    </h6>
                    <div className="bottom-text">
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.supers : 0} Super
                      </p>
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.leaders : 0}{" "}
                        {leaderscount?.leaders == 1 ||
                        leaderscount?.leaders == 0
                          ? "Leader"
                          : "Leader's"}
                      </p>
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.miniLeaders : 0}{" "}
                        Ambassador
                      </p>
                    </div>
                  </>
                ) : leaderscount?.role === "super_agent" ? (
                  <>
                    <h6>
                      {leaderscount
                        ? leaderscount?.leaders + leaderscount?.miniLeaders
                        : 0}
                    </h6>
                    <div className="bottom-text">
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.leaders : 0}{" "}
                        {leaderscount?.leaders == 1 ||
                        leaderscount?.leaders == 0
                          ? "Leader"
                          : "Leader's"}
                      </p>
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.miniLeaders : 0}{" "}
                        Ambassador
                      </p>
                    </div>
                  </>
                ) : leaderscount?.role === "agent" ? (
                  <>
                    <h6>{leaderscount ? leaderscount?.miniLeaders : 0}</h6>
                    <div className="bottom-text">
                      <p className="uni-text">
                        <span></span>
                        {leaderscount ? leaderscount?.miniLeaders : 0}{" "}
                        Ambassador
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}

            <div className="single-card">
              <img
                src="\assets\dashboard\noofpurchases.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Number of Purchases</p>

              <h6>
                {leaderscount?.totalDirectSales +
                  leaderscount?.totalInDirectSales}
              </h6>

              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.totalDirectSales} Direct
                </p>
                <p className="uni-text">
                  <span></span>
                  {leaderscount?.totalInDirectSales} Indirect
                </p>
              </div>
            </div>
            <div className="single-card">
              <img src="\gemslogo.svg" alt="img" className="gems-logo-img" />
              <p>GEMS Purchased</p>
              {usdPrice && leaderscount ? (
                <h6>
                  {parseInt(leaderscount?.totalDop?.toFixed(2)).toLocaleString(
                    "en-US"
                  )}{" "}
                  &nbsp;GEMS
                </h6>
              ) : (
                <h6>0.00 GEMS</h6>
              )}
              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseInt(
                        leaderscount?.totalDopDirect?.toFixed(4)
                      ).toLocaleString("en-US")
                    : 0}
                </p>
                <p className="uni-text">
                  <span></span>~
                  {leaderscount
                    ? parseInt(
                        leaderscount?.totalDopIndirect?.toFixed(4)
                      ).toLocaleString("en-US")
                    : 0}
                </p>
              </div>
            </div>
            <div className="single-card">
              <img
                src="\assets\dashboard\purchasedamount.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Purchased Amount</p>
              {usdPrice && leaderscount ? (
                <>
                  <h6 className="datahead">
                    ~$
                    {parseFloat(
                      (
                        usdPrice * leaderscount?.totalEthDirect +
                        leaderscount?.totalUsdtDirect +
                        tomiPrice * leaderscount?.totalTomiDirect +
                        btcPrice * leaderscount?.totalWbtcDirect +
                        leaderscount?.totalUsdcDirect
                      )?.toFixed(2)
                    ).toLocaleString("en-US")}{" "}
                  </h6>
                </>
              ) : (
                <h6 className="datahead">~$0.00 USD</h6>
              )}
              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {/* ? (user?.totalEth + user?.totalEthIndirect)?.toFixed(4) */}
                  {leaderscount ? leaderscount?.totalEthDirect?.toFixed(4) : 0}{" "}
                  ETH
                </p>
                <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(
                        leaderscount?.totalUsdtDirect?.toFixed(2)
                      )?.toLocaleString("en-US")
                    : 0}{" "}
                  USDT
                </p>
                {/* <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(
                        leaderscount?.totalTomiDirect?.toFixed(2)
                      )?.toLocaleString("en-US")
                    : 0}{" "}
                  TOMI
                </p> */}
                <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(
                        leaderscount?.totalWbtcDirect?.toFixed(5)
                      )?.toLocaleString("en-US")
                    : 0}{" "}
                  WBTC
                </p>{" "}
                <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(
                        leaderscount?.totalUsdcDirect?.toFixed(2)
                      )?.toLocaleString("en-US")
                    : 0}{" "}
                  USDC
                </p>
              </div>
            </div>
            <div className="single-card">
              <img
                src="\assets\dashboard\agentearning.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Earnings</p>
              {/* <h6>~50,000 USD</h6> */}
              {usdPrice && leaderscount ? (
                <h6 className="datahead">
                  ~$
                  {parseFloat(
                    (
                      usdPrice * leaderscount?.myEarningsEth +
                      leaderscount?.myEarningsUsdt +
                      tomiPrice * leaderscount?.myEarningsTomi +
                      btcPrice * leaderscount?.myEarningsWbtc +
                      leaderscount?.myEarningsUsdc
                    )?.toFixed(4)
                  ).toLocaleString("en-US")}{" "}
                </h6>
              ) : (
                <h6 className="datahead">~$0.00 USD</h6>
              )}
              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(leaderscount?.myEarningsEth)?.toFixed(5)
                    : ""}{" "}
                  ETH
                </p>
                <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(
                        leaderscount?.myEarningsUsdt?.toFixed(4)
                      )?.toLocaleString("en-US")
                    : 0}{" "}
                  USDT
                </p>
                {/* <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(
                        leaderscount?.myEarningsTomi?.toFixed(5)
                      )?.toLocaleString("en-US")
                    : 0}{" "}
                  TOMI
                </p> */}
                <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(leaderscount?.myEarningsWbtc?.toFixed(6))
                    : 0}{" "}
                  WBTC
                </p>{" "}
                <p className="uni-text">
                  <span></span>
                  {leaderscount
                    ? parseFloat(
                        leaderscount?.myEarningsUsdc?.toFixed(4)
                      )?.toLocaleString("en-US")
                    : 0}{" "}
                  USDC
                </p>
              </div>
            </div>
          </div>
          <div className="parent-new-card">
            <div className="main-headingg">
              <h6>General Information</h6>
            </div>
            <div className="bottom-cards1">
              <div className="inner-text">
                <p>Access Code</p>
                <h6>{leaderscount?.accessCode}</h6>
              </div>
              <div className="inner-text">
                <p>Name</p>
                <h6>{leaderscount?.name}</h6>
              </div>
              <div className="inner-text">
                <p>Direct Sale</p>
                {leaderscount?.totalDirectSales != 0 ||
                leaderscount?.totalInDirectSales != 0 ? (
                  <h6>
                    {parseInt(
                      (leaderscount?.totalDirectSales /
                        (leaderscount?.totalDirectSales +
                          leaderscount?.totalInDirectSales)) *
                        100
                    ).toFixed(2)}
                    %
                  </h6>
                ) : (
                  <h6>0%</h6>
                )}
              </div>
              <div className="inner-text">
                <p>Indirect Sale</p>

                {leaderscount?.totalDirectSales != 0 ||
                leaderscount?.totalInDirectSales != 0 ? (
                  <h6>
                    {parseInt(
                      (leaderscount?.totalInDirectSales /
                        (leaderscount?.totalDirectSales +
                          leaderscount?.totalInDirectSales)) *
                        100
                    ).toFixed(2)}
                    %
                  </h6>
                ) : (
                  <h6>0%</h6>
                )}
              </div>
              <div className="inner-text">
                <p>Created On</p>
                <h6>{moment(leaderscount).format("DD/MM/YYYY hh:mm")} UTC</h6>
              </div>
              <div className="inner-text">
                <p>Email address</p>
                <h6>{leaderscount?.email}</h6>
              </div>
              <div className="inner-text">
                <p>Owner’s Wallet Address</p>
                <h6 className="forflex">
                  {userDel?.walletAddress}
                  {/* <img
                    src="\assets\editi.svg"
                    alt="img"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowchange}
                  /> */}
                  {/* // ?.slice(0, 11)}...
                  // {userDel?.walletAddress?.slice( */}
                  {/* //   userDel?.walletAddress?.length - 3,
                  //   userDel?.walletAddress?.length
                  // )} */}
                </h6>
              </div>
            </div>
            {user?.role == "admin" ? (
              <div className="bottom-cards2">
                <h4>Hierarchy</h4>
                <div className="parent-card">
                  <div className="inner-text">
                    <p>God Leader</p>
                    {leaderscount?.godAgent ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              onClick={() => {
                                setUserDel(leaderscount?.godAgent);
                              }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {leaderscount?.godAgent?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{leaderscount?.godAgent?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Mega Leader</p>
                    {leaderscount?.megaAgent ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              onClick={() => {
                                setUserDel(leaderscount?.megaAgent);
                              }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {leaderscount?.megaAgent?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{leaderscount?.megaAgent?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Super Leader</p>
                    {leaderscount?.superAgent ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              onClick={() => {
                                setUserDel(leaderscount?.superAgent);
                              }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {leaderscount?.superAgent?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{leaderscount?.superAgent?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Leader</p>
                    {leaderscount?.agent ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              onClick={() => {
                                setUserDel(leaderscount?.agent);
                              }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {leaderscount?.agent?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{leaderscount?.agent?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {/* {user?.role == "admin" ? (
              <div className="bottom-cards33">
                <h6>Code Buying Limit</h6>
                <p onClick={() => setShow123(true)}>
                  $ {leaderscount?.minimumBuy ? leaderscount?.minimumBuy : 0}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                      fill="white"
                    />
                    <path
                      d="M15.85 2.90005C14.2333 1.28338 12.65 1.24172 10.9916 2.90005L9.98331 3.90838C9.89998 3.99172 9.86665 4.12505 9.89998 4.24172C10.5333 6.45005 12.3 8.21672 14.5083 8.85005C14.5416 8.85838 14.575 8.86672 14.6083 8.86672C14.7 8.86672 14.7833 8.83338 14.85 8.76672L15.85 7.75838C16.675 6.94172 17.075 6.15005 17.075 5.35005C17.0833 4.52505 16.6833 3.72505 15.85 2.90005Z"
                      fill="white"
                    />
                    <path
                      d="M13.0083 9.60829C12.7666 9.49163 12.5333 9.37496 12.3083 9.24163C12.125 9.13329 11.95 9.01663 11.775 8.89163C11.6333 8.79996 11.4666 8.66663 11.3083 8.53329C11.2916 8.52496 11.2333 8.47496 11.1666 8.40829C10.8916 8.17496 10.5833 7.87496 10.3083 7.54163C10.2833 7.52496 10.2416 7.46663 10.1833 7.39163C10.1 7.29163 9.95831 7.12496 9.83331 6.93329C9.73331 6.80829 9.61665 6.62496 9.50831 6.44163C9.37498 6.21663 9.25831 5.99163 9.14165 5.75829C8.98868 5.43051 8.55847 5.33314 8.3027 5.58891L3.61665 10.275C3.50831 10.3833 3.40831 10.5916 3.38331 10.7333L2.93331 13.925C2.84998 14.4916 3.00831 15.025 3.35831 15.3833C3.65831 15.675 4.07498 15.8333 4.52498 15.8333C4.62498 15.8333 4.72498 15.825 4.82498 15.8083L8.02498 15.3583C8.17498 15.3333 8.38331 15.2333 8.48331 15.125L13.1771 10.4312C13.4278 10.1805 13.3336 9.74924 13.0083 9.60829Z"
                      fill="white"
                    />
                  </svg>
                </p>
              </div>
            ) : null} */}

            {/* {user?.role == "admin" ? (
              <div className="bottom-cards2 bottom-cards3">
                <h4>Special Access</h4>
                <div className="parent-card">
                  {specialAccess?.length > 0
                    ? specialAccess?.map((item) => {
                        return (
                          <>
                            <div className="inner-text">
                              <p>God Leader</p>
                              {leaderscount?.role == "god_agent" ? (
                                <>
                                  <h6 className="style-text">{item?.name}</h6>
                                </>
                              ) : (
                                <h6>None</h6>
                              )}
                            </div>
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            ) : null} */}
          </div>
          {/* {user?.role == "admin" ? (
            <Specialaccess
              specialAccess={specialAccess}
              setUserDel={setUserDel}
              setShow1={setShow1}
            />
          ) : null} */}

          <Tabs
            defaultActiveKey="transactions"
            id="uncontrolled-tab-example"
            className="viewdetail-tabs"
            onSelect={(e) => {
              setTabKey(e);
            }}
          >
            <Tab eventKey="transactions" title="All Transactions">
              <div className="main-heading111">
                <div
                  className={
                    userDel?.role !== "agent"
                      ? "rightside-content fxrxxrxxrexxr"
                      : "rightside-content "
                  }
                >
                  <div
                    className=" custom-option-field"
                    style={{ width: "85%" }}
                  >
                    {/* <form onSubmit={(e) => GetSearch(e)}> */}
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      type="search"
                      placeholder="Search..."
                    />
                    {/* </form> */}
                    <img
                      style={{ cursor: "pointer" }}
                      src="\assets\search-icon.svg"
                      alt="img"
                      className="img-fluid search-icon"
                      // onClick={() => GetSearch()}
                    />
                  </div>
                  {userDel?.role !== "mini_agent" && (
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Type{" "}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </button>
                      {userDel?.role === "god_agent" && (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("")}
                              class="dropdown-item"
                              href="#"
                            >
                              All Leaders
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("god_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              God Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("mega_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Mega Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("super_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Super Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("mini_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {userDel?.role === "mega_agent" && (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("")}
                              class="dropdown-item"
                              href="#"
                            >
                              All Leaders
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("mega_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Mega Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("super_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Super Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("mini_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Ambassador
                            </a>
                          </li>
                          {/* <li>
                          <a
                            onClick={() => GetFilterRole("god_agent")}
                            class="dropdown-item"
                            href="#"
                          >
                            God Leader
                          </a>
                        </li> */}
                        </ul>
                      )}
                      {userDel?.role === "super_agent" && (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("")}
                              class="dropdown-item"
                              href="#"
                            >
                              All Leaders
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Leader
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("mini_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {userDel?.role === "agent" && (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("")}
                              class="dropdown-item"
                              href="#"
                            >
                              All Leaders
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("mini_agent")}
                              class="dropdown-item"
                              href="#"
                            >
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  )}
                  {/* <button className="reset-btn-global">Reset</button> */}
                </div>
              </div>
              {transactions?.length > 0 ? (
                <DataTable
                  tomiPrice={tomiPrice}
                  transactions={transactions}
                  setTransactions={setTransactions}
                  user={user}
                  usdPrice={usdPrice}
                  page={page1}
                  pageCount={pageCount}
                  setOrderDirection={setOrderDirection}
                  setOrderField={setOrderField}
                />
              ) : (
                <p
                  className=""
                  style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
                >
                  No data found!
                </p>
              )}
              <div className="footer-content mt-2">
                <div className="left-f">
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                  {/* <h6>SHOWING 1-10 OF 145</h6> */}
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <nav aria-label="Page navigation example">
                        <ul class="text-center">
                          <ReactPaginate
                            previousLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M10 13L5 8L10 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            nextLabel={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                              >
                                <path
                                  d="M5 13L10 8L5 3"
                                  stroke="#fff"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={page1}
                          ></ReactPaginate>
                        </ul>
                      </nav>
                    </ul>
                  </nav>
                </div>
              </div>
            </Tab>
            {userDel?.role != "mini_agent" ? (
              <Tab eventKey="leaders" title="All Leaders">
                <div className="main-heading2 ">
                  <div
                    className={
                      userDel?.role == "mini_agent"
                        ? "rightside-content gvcvtdvdvtdcvdtc"
                        : "rightside-content"
                    }
                  >
                    <div className="custom-option-field">
                      {/* <form onSubmit={(e) => GetAgents(e)}> */}
                      <input
                        type="search"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                      {/* </form> */}
                      <img
                        style={{ cursor: "pointer" }}
                        src="\assets\search-icon.svg"
                        alt="img"
                        className="img-fluid search-icon"
                        // onClick={() => GetAgents()}
                      />
                    </div>
                    {userDel?.role == "mini_agent" ? (
                      ""
                    ) : (
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div
                            // onClick={() => {
                            //   setRoleTypeFilter(roleTypeFilter);
                            // }}
                            className="se-custom-flex-gap"
                          >
                            {roleTypeFilter == "god_agent"
                              ? "God"
                              : roleTypeFilter == "mega_agent"
                              ? "Mega"
                              : roleTypeFilter == "super_agent"
                              ? "Super"
                              : roleTypeFilter == "agent"
                              ? "Leader "
                              : roleTypeFilter == "mini_agent"
                              ? "Ambassador "
                              : "By Role"}
                            <img
                              src="\assets\arrow-down.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </button>
                        {userDel?.role == "admin" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("god_agent");
                                }}
                              >
                                God
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : userDel?.role == "god_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : userDel?.role == "mega_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : userDel?.role == "super_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : userDel?.role == "agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div
                          // onClick={() => {
                          //   setLeaderFilter("");
                          // }}
                          className="se-custom-flex-gap"
                        >
                          {leaderFilter ? leaderFilter + " " : "By Name "}

                          <img
                            src="\assets\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      </button>
                      <ul class="dropdown-menu set-custom-scrollbar">
                        <div className="custom-option-field drop-in-search-sticky">
                          <input type="search" placeholder="Search" />
                          <img
                            src="\assets\search-icon.svg"
                            alt="img"
                            className="img-fluid search-icon"
                          />
                        </div>
                        {leaderNames?.length > 0
                          ? leaderNames?.map((item) => {
                              return (
                                <>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      onClick={() => {
                                        setLeaderFilter(item?.name);
                                      }}
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                </>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                    {/* <button
                    className="reset-btn-global"
                    onClick={() => {
                      setLeaderFilter("");
                      setRoleTypeFilter("");
                      setSearchQuery("");
                    }}
                  >
                    Reset
                  </button> */}
                  </div>
                </div>
                {agents?.length > 0 ? (
                  <DataTableLeaders
                    // setUserDel={setUserDel}
                    btcPrice={btcPrice}
                    usdPrice={usdPrice}
                    tomiPrice={tomiPrice}
                    getNextData={getNextData}
                    currentPage={currentPage}
                    GetAllRounds={GetAgents}
                    pages={pages}
                    getPrevData={getPrevData}
                    GetAgents={GetAgents}
                    page={page}
                    pageCount={pageCountAgent}
                    handlePageChange={handleAgentsPageChange}
                    offset={offset}
                    setOffset={setOffset}
                    agents={agents}
                    setAgents={setAgents}
                    setRoutes={setRoutes}
                    sub={true}
                    pagination={true}
                  />
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </Tab>
            ) : null}

            {leaderscount?.role == "god_agent" &&
            leaderscount?.specialAccess?.length > 0 ? (
              <Tab eventKey="special" title="Special Access">
                <DataTableSpecialAccess
                  // setUserDel={setUserDel}
                  btcPrice={btcPrice}
                  tomiPrice={tomiPrice}
                  getNextData={getNextData}
                  currentPage={currentPage}
                  GetAllRounds={getSpecialAccessData}
                  pages={pages}
                  getPrevData={getPrevData}
                  GetAgents={getSpecialAccessData}
                  page={page}
                  pageCount={pageCountAgent}
                  handlePageChange={handleAgentsPageChange}
                  offset={offset}
                  setOffset={setOffset}
                  agents={specialAccessData}
                  setAgents={setAgents}
                  setRoutes={setRoutes}
                  sub={true}
                />

                {/* <Specialaccess
                  specialAccess={specialAccess}
                  setUserDel={setUserDel}
                  setShow1={setShow1}
                /> */}
              </Tab>
            ) : null}
          </Tabs>
        </div>
      </section>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Special Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="multiselect-custom mt-5">
            <label className="custom-label">Special Access to</label>
            <Multiselect
              placeholder=""
              selectedValues={specialAccess?.map((item) => item?.name)}
              isObject={false}
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck(selectedList, removedItem) {
                const selectedLeader = specialAccess.find(
                  (leader) => leader.name == removedItem
                );
                if (selectedLeader) {
                  const selectedId = selectedLeader._id;
                  const specialAccessExist = removeSpecialAccess?.find(
                    (leader) => leader == selectedId
                  );
                  if (!specialAccessExist) {
                    removeSpecialAccess.push(selectedId);
                  }
                  // Do whatever you need to with the selected id
                }
              }}
              onSearch={function noRefCheck() {}}
              onSelect={function noRefCheck(selectedList, selectedName) {
                const selectedLeader = godLeadersList.find(
                  (leader) => leader.name == selectedName
                );
                if (selectedLeader) {
                  const selectedId = selectedLeader._id;
                  const specialAccessExist = addSpecialAccess?.find(
                    (leader) => leader == selectedId
                  );
                  if (!specialAccessExist) {
                    addSpecialAccess.push(selectedId);
                  }
                  // Do whatever you need to with the selected id
                }
              }}
              customCloseIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.14307 12.8571L12.8574 5.14285"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8574 12.8571L5.14307 5.14285"
                    stroke="black"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              options={godLeadersList?.map((item) => item?.name)}
            />
          </div>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose1();
              }}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              disabled={loader}
              onClick={editSpecialAccessList}
              className="btn-add"
            >
              {loader ? "Edit..." : "Edit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show123}
        onHide={handleClose123}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Code Buying Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="number"
                  placeholder="Buying Limit (USD)"
                  value={minimumBuy}
                  onChange={(e) => setMinimumBuy(e.target.value)}
                />
                <label>Buying Limit (USD)</label>
              </div>
            </div>
          </div>
          <div className="twice-btn">
            <button className="btn-cancel" onClick={() => setMinimumBuy("")}>
              Back
            </button>
            <button
              className="btn-add"
              onClick={() => {
                changeMinimumBuy();
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss walletaddrssmodal"
        show={showchange}
        onHide={handleClosechange}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Wallet Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="walletaddrssmain">
            <p className="walletaddrsspara">Current Wallet Address</p>
            <h6 className="walletaddrsshead">{userDel?.walletAddress}</h6>
          </div>
          <div className="material-textfield">
            <input
              type="text"
              placeholder="Enter new wallet address"
              onChange={(e) => setNewWalletAddress(e.target.value)}
              value={newWalletAddress}
            />
            <label>New Wallet Address </label>
          </div>
          <div className="twice-btn">
            <button onClick={handleClosechange} className="btn-cancel">
              Cancel
            </button>
            <button
              className="btn-add"
              onClick={() => {
                handleClosechange();
                handleShowconfirmation();
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss walletaddrssmodal"
        show={showconfirmation}
        onHide={handleCloseconfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="paraconfirm">
            Are you sure you want to change wallet address
          </p>
          <div className="twice-btn">
            <button onClick={handleCloseconfirmation} className="btn-cancel">
              Cancel
            </button>
            <button
              className="btn-add"
              onClick={() => {
                handleCloseconfirmation();
                handleShowchangesuccess();
              }}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss walletaddrssmodal"
        show={showchangesuccess}
        onHide={handleClosechangesuccess}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ssuuccessmain">
            <img
              src="\assets\happy.svg"
              alt="successimg"
              className="successimg"
            />
            <p className="paraconfirm">Wallet address changed successfully</p>
            <button
              className="btn-okay w-100"
              onClick={handleClosechangesuccess}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDetail;
