import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import { round } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DataTable = ({
  setClaimsData,
  claimsData,
  usdPrice,
  setSelected,
  selected,
  claimStats,
  uptclaimset,
  round,
  checkBoxVal,
  xlId,
  tomiPrice,
  loader,
  btcPrice,
}) => {
  const [rend, setRend] = useState(false);
  const [checked, setChecked] = useState(false);
  const [copy, setCopy] = useState(false);

  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  const selectedNfts = (elem) => {
    let dumArr = [];
    if (selected.includes(elem)) {
      let res = selected?.filter((item) => {
        return item !== elem;
      });
      setSelected(res);
    } else {
      setSelected([...selected, elem]);
    }
  };
  const EarningsSort = (val) => {
    let data = claimsData;
    if (val === "asc") {
      data.sort(
        (a, b) =>
          (b?.ETHStats[0]?.amount * usdPrice || 0) +
          (b?.USDTStats[0]?.amount || 0) -
          ((a?.ETHStats[0]?.amount * usdPrice || 0) +
            (a?.USDTStats[0]?.amount || 0))
      );
    } else {
      data.sort(
        (a, b) =>
          (a?.ETHStats[0]?.amount * usdPrice || 0) +
          (a?.USDTStats[0]?.amount || 0) -
          ((b?.ETHStats[0]?.amount * usdPrice || 0) +
            (b?.USDTStats[0]?.amount || 0))
      );
    }
    // console.log(data)
    setClaimsData(data);
    setRend(!rend);
  };
  const selectAll = (e) => {
    if (e.target.checked) {
      setChecked(true);
      // let res = claimsData.filter((item) => {
      //   return !item?.claimsRound?.includes(parseInt(round))
      // })
      setSelected(claimsData);
    } else {
      setSelected([]);
      setChecked(false);
    }
  };
  let name = function convertToTitleCase(input) {
    // console.log("cccc");
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      if (words[words.length - 1].toLowerCase() === "agent") {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      } else if (words[0] === "super" || words[0] === "Super") {
        return "Super Leader";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <section className={"datatable"}>
        <div className="table-responsive">
          <table class="table" id={xlId}>
            <thead>
              <tr>
                <th>
                  <div class="custom-checkbox">
                    <input
                      onChange={selectAll}
                      type="checkbox"
                      id={`html_${xlId}`}
                    />
                    <label htmlFor={`html_${xlId}`}></label>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">Leader Name</div>
                </th>
                <th>
                  <div className="parent-tag">
                    Type{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Wallet Address{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Amount{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Allow{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Claim{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Rebuy{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {claimsData?.length > 0
                ? claimsData?.map((item, index) => {
                    let status = checked ? checked : null;

                    let statusAllowed = item?.claimsRound?.includes(
                      parseInt(round)
                    );

                    let value = parseFloat(item?.myEarningsEth);
                    let truncatedValue = Math.floor(value * 100000) / 100000;
                    const ethAmount = parseFloat(truncatedValue || 0)?.toFixed(
                      5
                    );
                    let value2 = parseFloat(item?.myEarningsUsdt);
                    let truncatedValue2 = Math.floor(value2 * 10000) / 10000;
                    const usdAmount = parseFloat(truncatedValue2 || 0)?.toFixed(
                      4
                    );
                    let valueTomi = parseFloat(item?.myEarningsTomi);
                    let truncatedValueTomi =
                      Math.floor(valueTomi * 10000) / 10000;
                    const tomiAmount = parseFloat(
                      truncatedValueTomi || 0
                    )?.toFixed(4);

                    let valueWbtc = parseFloat(item?.myEarningsWbtc);
                    let truncatedValueWbtc =
                      Math.floor(valueWbtc * 10000000) / 10000000;
                    const wbtcAmount = parseFloat(
                      truncatedValueWbtc || 0
                    )?.toFixed(6);

                    let valueUsdc = parseFloat(item?.myEarningsUsdc);
                    let truncatedValueUdsc =
                      Math.floor(valueUsdc * 10000) / 10000;
                    const usdcAmount = parseFloat(
                      truncatedValueUdsc || 0
                    )?.toFixed(4);

                    const result =
                      (value || 0) * usdPrice +
                      (value2 || 0) +
                      (valueTomi || 0) * tomiPrice +
                      (valueWbtc || 0) * btcPrice +
                      (valueUsdc || 0);
                    ///////////////////////////
                    let value3;
                    let ethAmountRebuy,
                      usdAmountRebuy,
                      tomiAmountRebuy,
                      wbtcAmountRebuy,
                      usdcAmountRebuy,
                      resultRebuy;
                    if (item?.rebuy && item?.rebuy?.length > 0) {
                      value3 = parseFloat(item?.rebuy[0]?.totalEth || 0);
                      let truncatedValue3 =
                        Math.floor(value3 * 100000) / 100000;
                      ethAmountRebuy = parseFloat(
                        truncatedValue3 || 0
                      )?.toFixed(5);
                      let value4 = parseFloat(item?.rebuy[0]?.totalUsdt || 0);
                      let truncatedValue4 = Math.floor(value4 * 10000) / 10000;
                      usdAmountRebuy = parseFloat(
                        truncatedValue4 || 0
                      )?.toFixed(4);

                      let value5 = parseFloat(item?.rebuy[0]?.totalTomi || 0);
                      let truncatedValue5 = Math.floor(value5 * 10000) / 10000;
                      tomiAmountRebuy = parseFloat(
                        truncatedValue5 || 0
                      )?.toFixed(5);

                      let value6 = parseFloat(item?.rebuy[0]?.totalWbtc || 0);
                      let truncatedValue6 =
                        Math.floor(value6 * 1000000) / 1000000;
                      wbtcAmountRebuy = parseFloat(
                        truncatedValue6 || 0
                      )?.toFixed(6);

                      let value7 = parseFloat(item?.rebuy[0]?.totalUsdc || 0);
                      let truncatedValue7 = Math.floor(value7 * 10000) / 10000;
                      usdcAmountRebuy = parseFloat(
                        truncatedValue7 || 0
                      )?.toFixed(5);

                      resultRebuy =
                        (value3 || 0) * usdPrice +
                        (value4 || 0) +
                        (value5 || 0) * tomiPrice +
                        (value6 || 0) * btcPrice +
                        (value7 || 0);
                    } else {
                      value3 = 0;
                      let truncatedValue3 =
                        Math.floor(value3 * 100000) / 100000;
                      ethAmountRebuy = parseFloat(
                        truncatedValue3 || 0
                      )?.toFixed(5);
                      let value4 = 0;
                      let truncatedValue4 = Math.floor(value4 * 10000) / 10000;
                      usdAmountRebuy = parseFloat(
                        truncatedValue4 || 0
                      )?.toFixed(4);
                      let value5 = 0;
                      let truncatedValue5 = Math.floor(value5 * 10000) / 10000;
                      tomiAmountRebuy = parseFloat(
                        truncatedValue5 || 0
                      )?.toFixed(5);

                      let value6 = 0;
                      let truncatedValue6 =
                        Math.floor(value6 * 1000000) / 1000000;
                      wbtcAmountRebuy = parseFloat(
                        truncatedValue6 || 0
                      )?.toFixed(6);
                      let value7 = 0;
                      let truncatedValue7 =
                        Math.floor(value7 * 100000) / 100000;
                      usdcAmountRebuy = parseFloat(
                        truncatedValue7 || 0
                      )?.toFixed(5);

                      resultRebuy =
                        (value3 || 0) * usdPrice +
                        (value4 || 0) +
                        // (value5 || 0) * tomiPrice +
                        (value6 || 0) * btcPrice +
                        (value7 || 0);
                    }
                    const wbtcConst = 0;
                    const wbtcFixed = wbtcConst.toFixed(6);

                    return (
                      <>
                        <tr
                          key={index}
                          className={
                            item?.walletAddress == uptclaimset
                              ? "ysvtvstyvtiivtyvs"
                              : "ysvtvstyvtivtyvs"
                          }
                        >
                          <td>
                            <div class="custom-checkbox">
                              <input
                                className={
                                  item?.ETHStats?.length === 0 &&
                                  item?.USDTStats?.length === 0 &&
                                  // item?.TOMIStats?.length === 0 &&
                                  item?.WBTCStats?.length === 0 &&
                                  item?.USDCStats?.length === 0 &&
                                  "nAllowed"
                                }
                                disabled={
                                  item?.ETHStats?.length === 0 &&
                                  item?.USDTStats?.length === 0 &&
                                  // item?.TOMIStats?.length === 0 &&
                                  item?.WBTCStats?.length === 0 &&
                                  item?.UDSCStats?.length === 0
                                }
                                checked={selected?.some(
                                  (elem) => elem._id === item?._id
                                )}
                                onChange={() => selectedNfts(item)}
                                type="checkbox"
                                id={item?._id + checkBoxVal}
                              />
                              <label
                                className={
                                  item?.ETHStats?.length === 0 &&
                                  item?.USDTStats?.length === 0 &&
                                  // item?.TOMIStats?.length === 0 &&
                                  item?.WBTCStats?.length === 0 &&
                                  item?.USDCStats?.length === 0 &&
                                  "nAllowed"
                                }
                                for={item?._id + checkBoxVal}
                              ></label>
                            </div>
                          </td>
                          <td>{item?.name}</td>
                          <td>{name(item?.role)}</td>
                          <td>
                            <>
                              <CopyToClipboard
                                text={item?.walletAddress}
                                onCopy={(e) => textCopiedFun(index)}
                              >
                                <span className="adadasdasdasdasdasdsad">
                                  {item?.walletAddress?.slice(0, 6)}...
                                  {item?.walletAddress?.slice(
                                    item?.walletAddress?.length - 3,
                                    item?.walletAddress?.length
                                  )}{" "}
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    className=""
                                  >
                                    <path
                                      d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                              </CopyToClipboard>
                              {copy === index && (
                                <p className="copy-text">Copied</p>
                              )}
                            </>
                            {/* {item?.walletAddress?.slice(0, 6)}...
                            {item?.walletAddress?.slice(-3)}{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  item?.walletAddress
                                );
                              }}
                            >
                              <path
                                d="M9.44316 8.37326L9.46954 10.8231C9.49152 12.8647 8.6837 13.6901 6.64215 13.7121L4.19229 13.7384C2.15074 13.7604 1.32533 12.9526 1.30334 10.9111L1.27696 8.46119C1.25498 6.41965 2.0628 5.59423 4.10435 5.57225L6.55421 5.54587C8.59576 5.52388 9.42117 6.33171 9.44316 8.37326Z"
                                stroke="white"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.9053 4.83578L12.9317 7.28564C12.9536 9.32719 12.1458 10.1526 10.1043 10.1746L9.46263 10.1815L9.44316 8.37327C9.42117 6.33172 8.59576 5.52389 6.55421 5.54588L4.74598 5.56535L4.73908 4.92372C4.71709 2.88217 5.52492 2.05676 7.56647 2.03477L10.0163 2.00839C12.0579 1.98641 12.8833 2.79423 12.9053 4.83578Z"
                                stroke="white"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg> */}
                          </td>
                          <td>
                            <div className="twice-text">
                              <h6>
                                ${" "}
                                {parseFloat(item?.totalAmountEarned).toFixed(4)}
                              </h6>

                              <p>
                                {ethAmount > 0 && (
                                  <span>
                                    {ethAmount} ETH
                                    {usdAmount > 0 ||
                                    tomiAmount > 0 ||
                                    wbtcAmount > wbtcFixed ||
                                    usdcAmount > 0
                                      ? " + "
                                      : ""}
                                  </span>
                                )}
                                {usdAmount > 0 && (
                                  <span>
                                    {usdAmount} USDT
                                    {tomiAmount > 0 ||
                                    wbtcAmount > wbtcFixed ||
                                    usdcAmount > 0
                                      ? " + "
                                      : ""}
                                  </span>
                                )}
                                {/* {tomiAmount > 0 && (
                                  <span>
                                    {tomiAmount} TOMI
                                    {wbtcAmount > 0 || usdcAmount > 0
                                      ? " + "
                                      : ""}
                                  </span>
                                )} */}
                                {wbtcAmount > wbtcFixed && (
                                  <span>
                                    {wbtcAmount} WBTC
                                    {usdcAmount > 0 ? " + " : ""}
                                  </span>
                                )}
                                {usdcAmount > 0 && (
                                  <span>{usdcAmount} USDC</span>
                                )}
                              </p>
                            </div>
                          </td>
                          {statusAllowed ? (
                            <td className="ended-text">Allowed</td>
                          ) : (
                            <td className=" pending">Pending</td>
                          )}
                          {item?.claim && item?.claim?.length > 0 ? (
                            <td className=" ended-text">Claimed</td>
                          ) : item?.rebuy && item?.rebuy?.length > 0 ? (
                            <td className=" ended-text">Rebought</td>
                          ) : (
                            <td className="pending">Unclaimed</td>
                          )}

                          <td>
                            <div className="twice-text">
                              <h6>${parseFloat(resultRebuy).toFixed(4)}</h6>
                              <p>
                                {ethAmountRebuy > 0 && (
                                  <span>
                                    {ethAmountRebuy} ETH
                                    {usdAmountRebuy > 0 ||
                                    tomiAmountRebuy > 0 ||
                                    wbtcAmountRebuy > 0 ||
                                    usdcAmountRebuy > 0
                                      ? " + "
                                      : ""}
                                  </span>
                                )}
                                {usdAmountRebuy > 0 && (
                                  <span>
                                    {usdAmountRebuy} USDT
                                    {tomiAmountRebuy > 0 ||
                                    wbtcAmountRebuy > 0 ||
                                    usdcAmountRebuy > 0
                                      ? " + "
                                      : ""}
                                  </span>
                                )}
                                {/* {tomiAmountRebuy > 0 && (
                                  <span>
                                    {tomiAmountRebuy} TOMI
                                    {wbtcAmountRebuy > 0 || usdcAmountRebuy > 0
                                      ? " + "
                                      : ""}
                                  </span>
                                )} */}
                                {wbtcAmountRebuy > 0 && (
                                  <span>
                                    {wbtcAmountRebuy} WBTC
                                    {usdcAmountRebuy > 0 ? " + " : ""}
                                  </span>
                                )}
                                {usdcAmountRebuy > 0 && (
                                  <span>{usdcAmountRebuy} USDC</span>
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                : ""}
            </tbody>
          </table>
          {loader ? (
            <p
              className=""
              style={{
                color: "#fff",
                textAlign: "center",
                marginTop: 50,
                marginBottom: 50,
                width: "100%",
              }}
            >
              Loading...
            </p>
          ) : (
            claimsData?.length > 0 || (
              <p
                className=""
                style={{
                  color: "#fff",
                  textAlign: "center",
                  marginTop: 50,
                  marginBottom: 50,
                  width: "100%",
                }}
              >
                No data found!
              </p>
            )
          )}

          {/* <div className="footer-content">
            <div className="left-f">
              <h6>SHOWING 1-{claimStats?.count} OF {claimStats?.count}</h6>
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {"<"}
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link active" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {">"}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
        <div className="mobileview-claim d-none">
          <div className="claim-mobile-view">
            {claimsData?.length > 0
              ? claimsData?.map((item, id) => {
                  let status = checked ? checked : null;
                  let statusAllowed = item?.claimsRound?.includes(
                    parseInt(round)
                  );
                  let value = parseFloat(item?.myEarningsEth);
                  let truncatedValue = Math.floor(value * 10000) / 10000;
                  const ethAmount = parseFloat(truncatedValue || 0)?.toFixed(5);
                  let value2 = parseFloat(item?.myEarningsTomi);
                  let truncatedValue2 = Math.floor(value2 * 10000) / 10000;
                  const usdAmount = parseFloat(truncatedValue2 || 0)?.toFixed(
                    4
                  );
                  let valueTomi = parseFloat(item?.myEarningsTomi);
                  let truncatedValue3 = Math.floor(valueTomi * 10000) / 10000;
                  const tomiAmount = parseFloat(truncatedValue3 || 0)?.toFixed(
                    5
                  );

                  let valueWbtc = parseFloat(item?.myEarningsWbtc);
                  let truncatedValueWbtc =
                    Math.floor(valueWbtc * 1000000) / 1000000;
                  const wbtcAmount = parseFloat(
                    truncatedValueWbtc || 0
                  )?.toFixed(6);
                  let valueUsdc = parseFloat(item?.myEarningsUsdc);
                  let truncatedValueUdsc =
                    Math.floor(valueUsdc * 10000) / 10000;
                  const usdcAmount = parseFloat(
                    truncatedValueUdsc || 0
                  )?.toFixed(4);

                  const result =
                    (value || 0) * usdPrice +
                    (value2 || 0) +
                    (valueTomi || 0) * tomiPrice +
                    (valueWbtc || 0) * btcPrice +
                    (valueUsdc || 0);

                  let value3;
                  let ethAmountRebuy,
                    usdAmountRebuy,
                    tomiAmountRebuy,
                    wbtcAmountRebuy,
                    usdcAmountRebuy,
                    resultRebuy;
                  if (item?.rebuy && item?.rebuy?.length > 0) {
                    value3 = parseFloat(item?.rebuy[0]?.totalEth || 0);
                    let truncatedValue3 = Math.floor(value3 * 100000) / 100000;
                    ethAmountRebuy = parseFloat(truncatedValue3 || 0)?.toFixed(
                      5
                    );
                    let value4 = parseFloat(item?.rebuy[0]?.totalUsdt || 0);
                    let truncatedValue4 = Math.floor(value4 * 10000) / 10000;
                    usdAmountRebuy = parseFloat(truncatedValue4 || 0)?.toFixed(
                      4
                    );

                    let value5 = parseFloat(item?.rebuy[0]?.totalTomi || 0);
                    let truncatedValue5 = Math.floor(value5 * 10000) / 10000;
                    tomiAmountRebuy = parseFloat(truncatedValue5 || 0)?.toFixed(
                      5
                    );

                    let value6 = parseFloat(item?.rebuy[0]?.totalWbtc || 0);
                    let truncatedValue6 = Math.floor(value6 * 100000) / 100000;
                    wbtcAmountRebuy = parseFloat(truncatedValue6 || 0)?.toFixed(
                      5
                    );

                    let value7 = parseFloat(item?.rebuy[0]?.totalUsdc || 0);
                    let truncatedValue7 = Math.floor(value7 * 10000) / 10000;
                    usdcAmountRebuy = parseFloat(truncatedValue7 || 0)?.toFixed(
                      5
                    );

                    resultRebuy =
                      (value3 || 0) * usdPrice +
                      (value4 || 0) +
                      // (value5 || 0) * tomiPrice +
                      (value6 || 0) * btcPrice +
                      (value7 || 0);
                  } else {
                    value3 = 0;
                    let truncatedValue3 = Math.floor(value3 * 100000) / 100000;
                    ethAmountRebuy = parseFloat(truncatedValue3 || 0)?.toFixed(
                      5
                    );
                    let value4 = 0;
                    let truncatedValue4 = Math.floor(value4 * 10000) / 10000;
                    usdAmountRebuy = parseFloat(truncatedValue4 || 0)?.toFixed(
                      4
                    );
                    let value5 = 0;
                    let truncatedValue5 = Math.floor(value5 * 10000) / 10000;
                    tomiAmountRebuy = parseFloat(truncatedValue5 || 0)?.toFixed(
                      5
                    );

                    let value6 = 0;
                    let truncatedValue6 =
                      Math.floor(value6 * 1000000) / 1000000;
                    wbtcAmountRebuy = parseFloat(truncatedValue6 || 0)?.toFixed(
                      6
                    );
                    let value7 = 0;
                    let truncatedValue7 = Math.floor(value7 * 100000) / 100000;
                    usdcAmountRebuy = parseFloat(truncatedValue7 || 0)?.toFixed(
                      5
                    );

                    resultRebuy =
                      (value3 || 0) * usdPrice +
                      (value4 || 0) +
                      // (value5 || 0) * tomiPrice +
                      (value6 || 0) * btcPrice +
                      (value7 || 0);
                  }
                  return (
                    <div
                      className={
                        item?.walletAddress == uptclaimset
                          ? "inner-card ysvtvstyvtiivtyvs"
                          : "inner-card ysvtvstyvtivtyvs"
                      }
                    >
                      <div className="left-side">
                        <div class="custom-checkbox">
                          <input
                            className={
                              item?.ETHStats?.length === 0 &&
                              item?.USDTStats?.length === 0 &&
                              // item?.TOMIStats?.length === 0 &&
                              item?.WBTCStats?.length === 0 &&
                              item?.USDCStats?.length === 0 &&
                              "nAllowed"
                            }
                            disabled={
                              item?.ETHStats?.length === 0 &&
                              item?.USDTStats?.length === 0 &&
                              // item?.TOMIStats?.length === 0 &&
                              item?.WBTCStats?.length === 0 &&
                              item?.UDSCStats?.length === 0
                            }
                            checked={selected?.some(
                              (elem) => elem._id === item?._id
                            )}
                            onChange={() => selectedNfts(item)}
                            type="checkbox"
                            id={id + checkBoxVal}
                          />
                          <label
                            className={
                              item?.ETHStats?.length === 0 &&
                              item?.USDTStats?.length === 0 &&
                              // item?.TOMIStats?.length === 0 &&
                              item?.WBTCStats?.length === 0 &&
                              item?.USDCStats?.length === 0 &&
                              "nAllowed"
                            }
                            for={id + checkBoxVal}
                          ></label>{" "}
                        </div>
                      </div>
                      <div className="right-side">
                        <div className="inner-text">
                          <p>Leader Name</p>
                          <h6>{item?.name}</h6>
                        </div>
                        <div className="inner-text">
                          <p>Type</p>
                          <h6>{name(item?.role)}</h6>
                        </div>
                        <div className="inner-text">
                          <p>Wallet Address</p>
                          <h6>
                            {item?.walletAddress?.slice(0, 6)}...
                            {item?.walletAddress?.slice(-3)}{" "}
                            <svg
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  item?.walletAddress
                                );
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                            >
                              <path
                                d="M10.3582 7.57467L10.3845 10.0245C10.4065 12.0661 9.5987 12.8915 7.55716 12.9135L5.1073 12.9399C3.06575 12.9618 2.24034 12.154 2.21835 10.1125L2.19197 7.66261C2.16999 5.62106 2.97781 4.79565 5.01936 4.77367L7.46922 4.74728C9.51077 4.7253 10.3362 5.53313 10.3582 7.57467Z"
                                stroke="white"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.8203 4.03732L13.8466 6.48718C13.8686 8.52873 13.0608 9.35414 11.0193 9.37612L10.3776 9.38303L10.3582 7.57481C10.3362 5.53326 9.51076 4.72543 7.46921 4.74742L5.66098 4.76689L5.65407 4.12526C5.63208 2.08371 6.43991 1.2583 8.48146 1.23631L10.9313 1.20993C12.9729 1.18795 13.7983 1.99577 13.8203 4.03732Z"
                                stroke="white"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </h6>
                        </div>
                        <div className="inner-text">
                          <p>Status</p>
                          {statusAllowed ? (
                            <h6 className="ended-text">Allowed</h6>
                          ) : (
                            <h6 className=" pending">Pending</h6>
                          )}
                        </div>
                        <div className="inner-text">
                          <p>Amount</p>
                          <div className="twice-text">
                            <h6>
                              ${parseFloat(item?.totalAmountEarned)?.toFixed(4)}{" "}
                            </h6>
                            <p>
                              ({ethAmount} ETH + {usdAmount} USDT +
                              {/* {tomiAmount}{" "}
                              TOMI +  */}
                              {wbtcAmount} WBTC + {usdcAmount} USDC)
                            </p>
                          </div>
                        </div>
                        <div className="inner-text">
                          <p>Rebuy</p>
                          <div className="twice-text">
                            <h6>${parseFloat(resultRebuy).toFixed(4)}</h6>
                            <p>
                              ({ethAmountRebuy} ETH + {usdAmountRebuy} USDT +{" "}
                              {/* {tomiAmountRebuy} TOMI +  */}
                              {wbtcAmountRebuy} WBTC + {usdcAmountRebuy} USDC)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          {/* <div className="footer-content">
            <div className="left-f"></div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {"<"}
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link active" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {">"}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default DataTable;
