import web3NoAccount from "./web3";
import tomiContract from "./tomiAbi2.json";
import createRoundDop from "./PreSale.json";
import ERC20Token from "./erc20TokenABI.json";
import Claims from "./Claims.json";
import ClaimNewLatest from "./claimNewLatest.json";

import ClaimsNew from "./ClaimsNew.json";
import insitutionPreSale from "./institutionPreSaleAbi.json";

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi, address);
};

export const getTomiContract = (address, web3) => {
  return getContract(tomiContract, address, web3);
};

export const getPreSaleContract = (address, web3) => {
  return getContract(createRoundDop, address, web3);
};

export const getClaimsContract = (address, web3) => {
  return getContract(Claims, address, web3);
};

export const getERC20Token = (address, web3) => {
  return getContract(ERC20Token, address, web3);
};
///////////////////////////////////////////////////////////
export const getClaimNewLatest = (address, web3) => {
  return getContract(ClaimNewLatest, address, web3);
};

export const getclaimscontract = (address, web3) => {
  return getContract(Claims, address, web3);
};

export const getclaimsnewcontract = (address, web3) => {
  return getContract(ClaimsNew, address, web3);
};
export const getinstitutionpresalecontract = (address, web3) => {
  return getContract(insitutionPreSale, address, web3);
};
