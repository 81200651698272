import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Countrychart = ({ chartData, chartType }) => {
  const [series, setSeries] = useState([{ data: [] }]);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      background: "#000", // Set chart background color to black
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true, // Distribute bars evenly across the x-axis
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#545454"],
      },
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        const dataValue = parseFloat(val);
        if (!isNaN(dataValue)) {
          const formattedValue =
            chartType === "amount" ? `$${dataValue.toLocaleString()}` : val;
          return formattedValue;
        }
        return "";
      },
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: ["#fff"],
          fontSize: "14px",
        },
        rotateAlways: true, // Rotate labels automatically when there's insufficient space
        offsetY: 0,
        offsetX: 0,
        rotate: -35,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ["#fff"],
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
    },
    stroke: {
      show: false,
    },
    fill: {
      colors: ["#FFF"], // Set bar color to white
    },
    legend: {
      show: false, // Hide legends
    },

    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const category = w.globals.labels[dataPointIndex]; // Get the category (x-axis label)
        const capitalizedCategory =
          category.charAt(0).toUpperCase() + category.slice(1);
        const value = series[seriesIndex][dataPointIndex]; // Get the value of the data point
        const title = chartType === "amount" ? "Purchased Amount" : "Purchases";
        return `
      <div style="background-color: #fff; border: 1px solid #ccc; padding: 10px; border-radius: 5px;">
        <div style="font-weight: bold;">${capitalizedCategory}</div>
        <div>${title}: ${
          chartType === "amount" ? `$${value.toLocaleString()}` : value
        }</div>
      </div>
    `; // Customize the tooltip content
      },
    },
  });

  useEffect(() => {
    const categories = chartData?.map((item) => item?._id);
    const data = chartData?.map((item) => {
      if (chartType === "purchases") {
        return item?.total;
      } else {
        return parseInt(item?.amountInUsd);
      }
    });

    setSeries([{ data }]);
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories,
      },
    }));
  }, [chartData, chartType]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={window.innerWidth > 600 ? 350 : 200}
      />
    </div>
  );
};

export default Countrychart;
