import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Api_Url } from "../../../../utils/ApiUrl";
import { CopyToClipboard } from "react-copy-to-clipboard";

const RowListing = ({ index, item, usdPrice, user }) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [nr, setNr] = useState(0);
  const [earn, setEarn] = useState(0);
  const [earnT, setEarnT] = useState(0);
  const [rend, setRend] = useState(false);
  const [copy, setCopy] = useState(false);
  const [copy3, setCopy3] = useState(false);
  const [copy2, setCopy2] = useState(false);
  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  function formatTokenValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(2) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue?.toFixed(2);
    }
  }
  useEffect(() => {
    // console.log("data we get", item);
    // calculate NR
    if (item?.tokenSelected === "ETH") {
      let vals =
        parseFloat(item?.AgentAmount) / 1000000000000000000 +
        parseFloat(item?.GodAgentAmount) / 1000000000000000000 +
        parseFloat(item?.MegaAgentAmount) / 1000000000000000000 +
        parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
      let invest = parseFloat(item?.amountInvested) / 1000000000000000000;
      //   setEarnT(vals?.toFixed(4));
      setNr((invest - vals)?.toFixed(4));
      if (user?.role === "admin") {
        let vals =
          parseFloat(item?.AgentAmount) / 1000000000000000000 +
          parseFloat(item?.GodAgentAmount) / 1000000000000000000 +
          parseFloat(item?.MegaAgentAmount) / 1000000000000000000 +
          parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
        setEarn(vals);
      } else if (user?.role === "agent") {
        let vals = (
          parseFloat(item?.AgentAmount) / 1000000000000000000
        )?.toFixed(4);
        // console.log("VVVV!", vals);
        setEarn(vals);
        setEarn(vals);
        // console.log("VVVV!", vals);
      } else if (user?.role === "god_agent") {
        let vals = parseFloat(item?.GodAgentAmount) / 1000000000000000000;
        setEarn(vals);
      } else if (user?.role === "mega_agent") {
        let vals = parseFloat(item?.MegaAgentAmount) / 1000000000000000000;
        setEarn(vals);
      } else if (user?.role === "super_agent") {
        let vals = parseFloat(item?.SuperAgentAmount) / 1000000000000000000;
        setEarn(vals);
      }
    } else {
      let vals =
        parseFloat(item?.AgentAmount) / 1000000 +
        parseFloat(item?.GodAgentAmount) / 1000000 +
        parseFloat(item?.MegaAgentAmount) / 1000000 +
        parseFloat(item?.SuperAgentAmount) / 1000000;
      let invest = parseFloat(item?.amountInvested) / 1000000;
      //   setEarnT(vals?.toFixed(2));
      setNr((invest - vals)?.toFixed(2));
      if (user?.role === "admin") {
        let vals =
          parseFloat(item?.AgentAmount) / 1000000 +
          parseFloat(item?.GodAgentAmount) / 1000000 +
          parseFloat(item?.MegaAgentAmount) / 1000000 +
          parseFloat(item?.SuperAgentAmount) / 1000000;
        setEarn(vals);
      } else if (user?.role === "agent") {
        let vals = parseFloat(item?.AgentAmount) / 1000000;
        setEarn(vals);
      } else if (user?.role === "god_agent") {
        let vals = parseFloat(item?.GodAgentAmount) / 1000000;
        setEarn(vals);
      } else if (user?.role === "mega_agent") {
        let vals = parseFloat(item?.MegaAgentAmount) / 1000000;
        setEarn(vals);
      } else if (user?.role === "super_agent") {
        let vals = parseFloat(item?.SuperAgentAmount) / 1000000;
        setEarn(vals);
      }
    }

    let dumArr = [item?.code_code];
    const val = localStorage.getItem("accessToken");
    // const url = `${Api_Url}/users/names?accessCodes[]=${dumArr.join(
    //   "&accessCodes[]="
    // )}`;
    // var config = {
    //   method: "get",
    //   url: url,
    //   headers: {
    //     Authorization: "Bearer " + val,
    //   },
    // };

    // axios(config)
    //   .then(function (response) {
    //     if (response?.data?.data?.length > 0) {
    //       let obj = response?.data?.data[0];
    //       setName(obj?.name);
    //       setRole(obj?.role);
    //       // console.log("rrrrrr", obj)
    //     }
    //   })
    //   .catch(function (error) { });
  }, [item, user]);
  // console.log("valllee", earn);

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const textCopiedFun2 = (index) => {
    setCopy2(index);

    setTimeout(() => {
      setCopy2(false);
    }, 1000);
  };
  const textCopiedFun3 = (index) => {
    setCopy3(index);

    setTimeout(() => {
      setCopy3(false);
    }, 1000);
  };
  return (
    <>
      <tr className="destopviewss" key={index}>
        <td>
          <div className="twice-text">
            <h6>{moment(item?.transactionTime).utc().format("DD/MM/YYYY")}</h6>
            <p>
              {moment(item?.transactionTime)
                .utc()
                .utcOffset("+02:00")
                .format("hh:mm A")}{" "}
            </p>
          </div>
        </td>

        <td>
          <div className="twice-text">
            <h6>{item?.user[0]?.name}</h6>
            <p>
              {role === user?.role ? (
                <p>Direct</p>
              ) : (
                <p>{convertToTitleCase(item?.codeRole)}</p>
              )}
            </p>
          </div>
        </td>
        <td>
          <p className="codepara">
            <CopyToClipboard
              text={item?.code_code}
              onCopy={(e) => textCopiedFun(index)}
            >
              <p>
                {/* {item?.code_code?.slice(0, 3)}...
                {item?.code_code?.slice(
                  item?.code_code?.length - 3,
                  item?.code_code?.length
                )} */}
                {item?.code_code}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  className={copy === index && "copy-button "}
                >
                  <path
                    d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                    stroke="#C4C4C4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
            </CopyToClipboard>
            {copy === index && <p className="copy-text">Copied</p>}
          </p>
        </td>
        <td>
          <div className="twice-text">
            <p>
              {item?.location[0]?.ipAddress} / {item?.location[0]?.country}
            </p>
          </div>
        </td>
        {user?.role == "admin" ? (
          <td>
            <p className="codepara">
              <CopyToClipboard
                text={item?.transactionHash}
                onCopy={(e) => textCopiedFun3(index)}
              >
                <p>
                  {item?.transactionHash?.slice(0, 3)}...
                  {item?.transactionHash?.slice(
                    item?.transactionHash?.length - 3,
                    item?.transactionHash?.length
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    style={{ cursor: "pointer" }}
                    className={copy3 === index && "copy-button "}
                  >
                    <path
                      d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                      stroke="#C4C4C4"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </CopyToClipboard>
              {copy3 === index && <p className="copy-text">Copied</p>}
            </p>
          </td>
        ) : null}
        {/* {role === user?.role ? (
          <td>Direct</td>
        ) : (
          <td>{convertToTitleCase(item?.codeRole)}</td>
        )} */}

        <td>
          <CopyToClipboard
            text={item?.by}
            onCopy={(e) => textCopiedFun2(index)}
          >
            <p className="adadasdasdasdasdasdsad">
              {item?.by.slice(0, 4)}...
              {item?.by.slice(item?.by.length - 3, item?.by.length)}
              <svg
                style={{ cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                className={copy2 === index && "copy-button "}
              >
                <path
                  d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                  stroke="#C4C4C4"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {copy2 === index && <p className="copy-text">Copied</p>}
            </p>
          </CopyToClipboard>
        </td>
        {/* {item?.tokenSelected === "ETH" ? (
          <td>
            {parseFloat(parseFloat(item?.amountInvested))
              ?.toFixed(5)
              .toLocaleString("en-US")}{" "}
            {item?.tokenSelected}
          </td>
        ) : item?.tokenSelected === "WBTC" ? (
          <td>
            {parseFloat(parseFloat(item?.amountInvested))
              ?.toFixed(6)
              .toLocaleString("en-US")}{" "}
            {item?.tokenSelected}
          </td>
        ) : (
          <td>
            {parseFloat(
              parseFloat(item?.amountInvested)?.toFixed(2)
            ).toLocaleString("en-US")}{" "}
            {item?.tokenSelected}
          </td>
        )} */}
        <td>{item?.price ? item?.price : 0}</td>
        <td>
          <p>${formatTokenValue(item?.totalSaleConverted)}</p>
        </td>
        <td>{formatTokenValue(item?.dopPurchased)} TOKEN</td>
        <td>
          {/* <div className="twice-text">
            {user?.role == "admin" ? (
              <div className="twice-text">
                {item?.codeRole == "agent" ? (
                  <h6>
                    {parseFloat(item?.AgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : item?.codeRole == "god_agent" ? (
                  <h6>
                    {parseFloat(item?.GodAgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : item?.codeRole == "mega_agent" ? (
                  <h6>
                    {parseFloat(item?.MegaAgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : item?.codeRole == "super_agent" ? (
                  <h6>
                    {parseFloat(item?.SuperAgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : item?.codeRole == "mini_agent" ? (
                  <h6>
                    {parseFloat(item?.MiniAgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : (
                  ""
                )}
                {item?.tokenSelected === "ETH" ? (
                  <p>
                    {item?.codeRole == "agent" ? (
                      <>
                        (
                        {(parseFloat(item?.AgentAmount) * usdPrice)?.toFixed(2)}{" "}
                        USD)
                      </>
                    ) : item?.codeRole == "god_agent" ? (
                      <>
                        (
                        {(parseFloat(item?.GodAgentAmount) * usdPrice)?.toFixed(
                          2
                        )}{" "}
                        USD)
                      </>
                    ) : item?.codeRole == "mega_agent" ? (
                      <>
                        (
                        {(
                          parseFloat(item?.MegaAgentAmount) * usdPrice
                        )?.toFixed(2)}{" "}
                        USD)
                      </>
                    ) : item?.codeRole == "super_agent" ? (
                      <>
                        (
                        {(
                          parseFloat(item?.SuperAgentAmount) * usdPrice
                        )?.toFixed(2)}{" "}
                        USD)
                      </>
                    ) : item?.codeRole == "mini_agent" ? (
                      <>
                        (
                        {(
                          parseFloat(item?.MiniAgentAmount) * usdPrice
                        )?.toFixed(2)}{" "}
                        USD)
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="twice-text">
                {user?.role == "agent" ? (
                  <h6>
                    {parseFloat(item?.AgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : user?.role == "god_agent" ? (
                  <h6>
                    {parseFloat(item?.GodAgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : user?.role == "mega_agent" ? (
                  <h6>
                    {parseFloat(item?.MegaAgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : user?.role == "super_agent" ? (
                  <h6>
                    {parseFloat(item?.SuperAgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : user?.role == "mini_agent" ? (
                  <h6>
                    {parseFloat(item?.MiniAgentAmount).toFixed(4)}{" "}
                    {item?.tokenSelected}
                  </h6>
                ) : (
                  ""
                )}
                {item?.tokenSelected === "ETH" ? (
                  <p>
                    {user?.role == "agent" ? (
                      <>
                        (
                        {(parseFloat(item?.AgentAmount) * usdPrice)?.toFixed(2)}{" "}
                        USD)
                      </>
                    ) : user?.role == "god_agent" ? (
                      <>
                        (
                        {(parseFloat(item?.GodAgentAmount) * usdPrice)?.toFixed(
                          2
                        )}{" "}
                        USD)
                      </>
                    ) : user?.role == "mega_agent" ? (
                      <>
                        (
                        {(
                          parseFloat(item?.MegaAgentAmount) * usdPrice
                        )?.toFixed(2)}{" "}
                        USD)
                      </>
                    ) : user?.role == "super_agent" ? (
                      <>
                        (
                        {(
                          parseFloat(item?.SuperAgentAmount) * usdPrice
                        )?.toFixed(2)}{" "}
                        USD)
                      </>
                    ) : user?.role == "mini_agent" ? (
                      <>
                        (
                        {(
                          parseFloat(item?.MiniAgentAmount) * usdPrice
                        )?.toFixed(2)}{" "}
                        USD)
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  ""
                )}
              </div>
            )}
          </div> */}
          {item?.tokenSelected === "ETH"
            ? item?.amountInvested.toFixed(5)
            : item?.tokenSelected == "TOMI"
            ? item?.amountInvested.toFixed(3)
            : item?.tokenSelected === "WBTC"
            ? item?.amountInvested.toFixed(6)
            : item?.amountInvested.toFixed(2)}
          {item?.tokenSelected}
        </td>

        <td> {item?.type}</td>
        <td>
          {" "}
          $
          {item?.myEarningConverted !== undefined
            ? formatTokenValue(item.myEarningConverted)
            : 0}
        </td>
        {/* {user?.role == "admin" ? (
          <td>
            <div className="twice-text">
              {item?.tokenSelected === "ETH" ? (
                <h6>
                  {(item?.amountInvested - item?.myEarningsEth).toFixed(6)}{" "}
                  {item?.tokenSelected}
                </h6>
              ) : item?.tokenSelected === "TOMI" ? (
                <h6>
                  {(item?.amountInvested - item?.myEarningsTomi).toFixed(4)}{" "}
                  {item?.tokenSelected}
                </h6>
              ) : item?.tokenSelected === "WBTC" ? (
                <h6>
                  {(item?.amountInvested - item?.myEarningsWbtc).toFixed(6)}{" "}
                  {item?.tokenSelected}
                </h6>
              ) : item?.tokenSelected === "USDC" ? (
                <h6>
                  {(item?.amountInvested - item?.myEarningsUsdc).toFixed(4)}{" "}
                  {item?.tokenSelected}
                </h6>
              ) : (
                <h6>
                  {parseFloat(
                    item?.amountInvested - item?.myEarningsUsdt
                  )?.toLocaleString("en-US")}{" "}
                  {item?.tokenSelected}
                </h6>
              )}
              {item?.tokenSelected === "ETH" ? (
                <p>
                  (
                  {(
                    parseFloat(item?.amountInvested - item?.myEarningsEth) *
                    usdPrice
                  )?.toFixed(4)}{" "}
                  USD)
                </p>
              ) : (
                ""
              )}
            </div>
          </td>
        ) : (
          ""
        )} */}
      </tr>
      <div className="new-accordionmobile d-none">
        <div className="upper-body">
          <div className="inner-text">
            <h6>Date and time</h6>
            <p> {moment(item?.transactionTime).utc().format("DD/MM/YYYY")}</p>

            <p>
              {" "}
              {moment(item?.transactionTime)
                .utc()
                .utcOffset("+02:00")
                .format("hh:mm A")}
            </p>
          </div>
          <div className="inner-text">
            <h6>Leader</h6>
            <p>{item?.user[0]?.name}</p>
          </div>
          <div className="inner-text">
            <h6>Sale Type</h6>
            {user?.role === role ? (
              <p>Direct</p>
            ) : (
              <p>{convertToTitleCase(item?.codeRole)}</p>
            )}
          </div>
          <div className="inner-text">
            <h6>Amount Spend</h6>
            {item?.tokenSelected === "ETH" ? (
              <p>
                {parseFloat(parseFloat(item?.amountInvested))
                  ?.toFixed(5)
                  .toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            ) : item?.tokenSelected === "WBTC" ? (
              <p>
                {parseFloat(parseFloat(item?.amountInvested))
                  ?.toFixed(6)
                  .toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            ) : (
              <p>
                {parseFloat(
                  parseFloat(item?.amountInvested)?.toFixed(2)
                ).toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            )}
          </div>
        </div>
        <div className="bottom-body">
          <Accordion>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                More Info{" "}
                <img
                  src="\assets\arrow-down.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className="inner-textt">
                  <p>IP/Country</p>
                  <h6>
                    {item?.location[0]?.ipAddress} /{" "}
                    {item?.location[0]?.country}
                  </h6>
                </div>
                <div className="inner-textt">
                  <p>Hash</p>
                  <h6>
                    {" "}
                    <a
                      href={"https://etherscan.io/tx/" + item?.transactionHash}
                      target="_blank"
                    >
                      Etherscan
                    </a>
                  </h6>
                </div>
                <div className="inner-textt">
                  <p>Wallet Address</p>
                  {copy ? (
                    <h6 className="adadasdasdasdasdasdsad">
                      {item?.by?.slice(0, 6)}...
                      {item?.by?.slice(
                        item?.by?.length - 3,
                        item?.by?.length
                      )}{" "}
                      <svg
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className=""
                      >
                        <path
                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </h6>
                  ) : (
                    <>
                      {item?.by && (
                        <CopyToClipboard
                          text={item?.by}
                          onCopy={textCopiedFun2}
                        >
                          <h6 className="adadasdasdasdasdasdsad">
                            {item?.by?.slice(0, 6)}...
                            {item?.by?.slice(
                              item?.by?.length - 3,
                              item?.by?.length
                            )}{" "}
                            <svg
                              style={{ cursor: "pointer" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              className=""
                            >
                              <path
                                d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </h6>
                        </CopyToClipboard>
                      )}
                    </>
                  )}
                </div>
                <div className="inner-textt">
                  <p>GEMS Purchased</p>
                  <h6>{(item?.dopPurchased).toFixed(2) + " GEMS"}</h6>
                </div>

                <div className="inner-textt">
                  <p>Type</p>
                  <h6>{item?.type}</h6>
                </div>

                <div className="inner-textt">
                  <p>Leader Earnings</p>
                  <h6>
                    $
                    {item?.myEarningConverted !== undefined
                      ? formatTokenValue(item.myEarningConverted)
                      : 0}
                  </h6>
                  {/* {user?.role == "admin" ? (
                    <div className="twice-text" style={{ textAlign: "right" }}>
                      <>
                        {item?.codeRole == "agent" ? (
                          <h6>
                            {parseFloat(item?.AgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : item?.codeRole == "god_agent" ? (
                          <h6>
                            {parseFloat(item?.GodAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : item?.codeRole == "mega_agent" ? (
                          <h6>
                            {parseFloat(item?.MegaAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : item?.codeRole == "super_agent" ? (
                          <h6>
                            {parseFloat(item?.SuperAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : item?.codeRole == "mini_agent" ? (
                          <h6>
                            {parseFloat(item?.MiniAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : (
                          ""
                        )}
                      </>

                      {item?.tokenSelected === "ETH" ? (
                        <p>
                          {item?.codeRole == "agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.AgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : item?.codeRole == "god_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.GodAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : item?.codeRole == "mega_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.MegaAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : item?.codeRole == "super_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.SuperAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : item?.codeRole == "mini_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.MiniAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="twice-text" style={{ textAlign: "right" }}>
                      <>
                        {user?.role == "agent" ? (
                          <h6>
                            {parseFloat(item?.AgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : user?.role == "god_agent" ? (
                          <h6>
                            {parseFloat(item?.GodAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : user?.role == "mega_agent" ? (
                          <h6>
                            {parseFloat(item?.MegaAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : user?.role == "super_agent" ? (
                          <h6>
                            {parseFloat(item?.SuperAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : user?.role == "mini_agent" ? (
                          <h6>
                            {parseFloat(item?.MiniAgentAmount).toFixed(4)}{" "}
                            {item?.tokenSelected}
                          </h6>
                        ) : (
                          ""
                        )}
                      </>

                      {item?.tokenSelected === "ETH" ? (
                        <p>
                          {user?.role == "agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.AgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : user?.role == "god_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.GodAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : user?.role == "mega_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.MegaAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : user?.role == "super_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.SuperAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : user?.role == "mini_agent" ? (
                            <h6>
                              (
                              {(
                                parseFloat(item?.MiniAgentAmount) * usdPrice
                              )?.toFixed(2)}{" "}
                              USD)
                            </h6>
                          ) : (
                            ""
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  )} */}
                </div>
                {/* {user?.role == "admin" ? (
                  <div className="inner-textt">
                    <p>Net Revenue</p>
                    <div className="twice-text" style={{ textAlign: "right" }}>
                      {item?.tokenSelected === "ETH" ? (
                        <h6>
                          {(item?.amountInvested - item?.myEarningsEth).toFixed(
                            6
                          )}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : // item?.tokenSelected === "TOMI" ? (
                      //   <h6>
                      //     {(
                      //       item?.amountInvested - item?.myEarningsTomi
                      //     ).toFixed(4)}{" "}
                      //     {item?.tokenSelected}
                      //   </h6>
                      // ) :

                      item?.tokenSelected === "WBTC" ? (
                        <h6>
                          {(
                            item?.amountInvested - item?.myEarningsWbtc
                          ).toFixed(6)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : item?.tokenSelected === "USDC" ? (
                        <h6>
                          {(
                            item?.amountInvested - item?.myEarningsUsdc
                          ).toFixed(4)}{" "}
                          {item?.tokenSelected}
                        </h6>
                      ) : (
                        <h6>
                          {parseFloat(
                            item?.amountInvested - item?.myEarningsUsdt
                          )?.toLocaleString("en-US")}{" "}
                          {item?.tokenSelected}
                        </h6>
                      )}
                      {item?.tokenSelected === "ETH" ? (
                        <p>
                          (
                          {(
                            parseFloat(
                              item?.amountInvested - item?.myEarningsEth
                            ) * usdPrice
                          )?.toFixed(4)}{" "}
                          USD)
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      {/* <Accordion.Item className="mobileviewnew" eventKey={index}>
        <Accordion.Header>
          <div className="accheadermain">
            <p className="acctext">
            {moment(item?.blockTimestamp * 1000).utc().format("DD/MM/YYYY")}
            </p>
            <a
              href={"https://etherscan.io/tx/" + item?.transactionHash}
              target="_blank"
            >
              <p className="acctext">
                <img
                  src="\assets\link-icon.svg"
                  alt="img"
                  className="img-fluid"
                />{" "}
              </p>
            </a>
            {role === user?.role ? (
              <p className="acctext">Direct</p>
            ) : (
              <p className="acctext">{convertToTitleCase(role)}</p>
            )}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="acctexts">
            <h6 className="textleft">Leader</h6>
            <p className="textright">{name}</p>
          </div>
          <div className="acctexts">
            <h6 className="textleft">Wallet Address</h6>
            <p className="textright">
              {item?.by?.slice(0, 6)}...
              {item?.by?.slice(item?.by?.length - 3, item?.by?.length)}{" "}
              <img src="\assets\copy.svg" alt="img" className="img-fluid" />
            </p>
          </div>
          <div className="acctexts">
            <h6 className="textleft">Amount Spent</h6>
            {item?.tokenSelected === "ETH" ? (
              <p className="textright">
                {parseFloat(parseInt(item?.amountInvested) / 1000000000000000000)
                  ?.toFixed(4)
                  .toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            ) : (
              <p className="textright">
                {parseFloat(
                  (parseFloat(item?.amountInvested) / 1000000)?.toFixed(2)
                ).toLocaleString("en-US")}{" "}
                {item?.tokenSelected}
              </p>
            )}
          </div>
          <div className="acctexts">
            <h6 className="textleft">DOP Purchased</h6>
            {item?.tokenSelected === "ETH" ? (
              <p className="textright">
                {parseFloat(
                  (usdPrice *
                    (parseFloat(item?.amountInvested) / 1000000000000000000)) /
                    (parseFloat(item?.price) / 1000000000000000000)
                )?.toLocaleString("en-US")}{" "}
                DOP
              </p>
            ) : (
              <p className="textright">
                {parseFloat(
                  (
                    parseFloat(item?.amountInvested) /
                    1000000 /
                    (parseFloat(item?.price) / 1000000000000000000)
                  )?.toFixed(2)
                )?.toLocaleString("en-US")}{" "}
                DOP
              </p>
            )}
          </div>
          <div className="acctexts">
            <h6 className="textleft">My Earnings</h6>
            <div className="twice-text">
              <h6>${earn}</h6>
              {item?.tokenSelected === "ETH" ? (
                <p>({earn * usdPrice} USD)</p>
              ) : (
                ""
              )}
            </div>
          </div>
          {user?.role == "admin" ? (
            <div className="acctexts">
              <h6 className="textleft">Net Revenue</h6>
              <div className="twice-text">
                <h6>
                  {parseFloat(nr)?.toLocaleString("en-US")}{" "}
                  {item?.tokenSelected}
                </h6>
                {item?.tokenSelected === "ETH" ? (
                  <p>({(parseFloat(nr) * usdPrice)?.toFixed(4)} USD)</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </Accordion.Body>
      </Accordion.Item> */}
    </>
  );
};

export default RowListing;
