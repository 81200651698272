import React, { useState, useEffect } from "react";
import DataTable from "./DataTable/DataTable";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Api_Url } from "../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";

const InstituteTransaction = ({
  user,
  setindexwait,
  tomiPrice,
  usdPrice,
  btcPrice,
}) => {
  const [codeDataCall, setCodeDataCall] = useState();
  const tok = localStorage?.getItem("accessToken");

  const [transactionApiCalled, setTransactionApiCalled] = useState(false);
  const [data, setData] = useState([]);
  const [totalT, setTotalT] = useState(0);
  const [purchases, setPurchases] = useState(null);
  const [dops, setDops] = useState(null);
  const [earnings, setEarnings] = useState(null);
  const [total, setTotal] = useState(0);
  // const [usdPrice, setUsdPrice] = useState(0);
  const [dopPrice, setDopPrice] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [code, setCode] = useState([]);
  const [pc, setPC] = useState(0);
  const [pmc, setPMC] = useState(0);
  const [nrc, setNRC] = useState(0);
  const [lc, setLC] = useState(0);
  const [dpc, setDPC] = useState(0);
  const [lec, setLEC] = useState(0);
  const [mm, setmm] = useState(0);
  const [ss, setss] = useState(0);
  const [gg, setgg] = useState(0);
  const [pee, setPEE] = useState(0);
  const [peu, setPEU] = useState(0);
  const [pdp, setPDP] = useState(0);
  const [ind, setIND] = useState(0);
  const [dt, setDT] = useState(0);
  const [adminobj, setadminobj] = useState();
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [myTree, setMyTree] = useState(false);

  const [typeFilter, setTypeFilter] = useState("");
  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");
  const history = useHistory();

  const userObj = JSON.parse(localStorage.getItem("user"));
  // const [tomiPrice, setTomiPrice] = useState("");

  // const getTomiPrice = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.coingecko.com/api/v3/simple/price`,
  //       {
  //         params: {
  //           ids: "tomiNet",
  //           vs_currencies: "usd",
  //         },
  //       }
  //     );

  //     const data = response?.data;
  //     const priceInUSD = data["tominet"]?.usd;

  //     setTomiPrice(priceInUSD);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     // throw error;
  //   }
  // };

  // useEffect(() => {
  //   getTomiPrice();
  //   axios
  //     .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
  //     .then((res) => {
  //       setUsdPrice(res?.data?.USD);
  //       // setEthUsd(res.data);
  //     });
  // }, []);

  useEffect(() => {
    // getTotalList();
    // if (usdPrice) {
    getTransactionsData();
    // getTotalList2();
    // }
  }, [
    usdPrice,
    user,
    search,
    typeFilter,
    orderField,
    orderDirection,
    page,
    myTree,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const getTransactionsData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/institutions/all-transactions?limit=10&offset=${
        page + 1
      }&privateSale=GEMS`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };
    let dynamicQuery = config.url;
    if (search) {
      dynamicQuery += `&${searchBy}=${search}`;
    }
    if (typeFilter) {
      dynamicQuery += `&leaderType=${typeFilter}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }
    if (user?.role == "god_agent") {
      dynamicQuery += `&myTree=${myTree}`;
    }
    config.url = dynamicQuery;

    axios(config)
      .then(function (res) {
        // console.log("trx data: ", res?.data?.data?.transactions);
        setTransactions(res?.data?.data?.transactions);
        setPageCount(res?.data?.data?.pages);
        setTransactionApiCalled(true);

        // setLoader(false);
      })
      .catch(function (error) {
        setTransactionApiCalled(true);

        // setLoader(false);
      });
  };

  useEffect(() => {
    GetAllRounds();
    GetMyData();
  }, [usdPrice, user]);

  const functiontogetback = () => {
    localStorage.setItem("indexvalue", 0);
    setindexwait(0);
  };

  const GetAllRounds = async () => {
    // if (account) {
    var config = {
      method: "get",
      url: `${Api_Url}/institutions/stats?privateSale=GEMS`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        setadminobj(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    // }
  };

  const GetMyData = async () => {
    const tok = localStorage?.getItem("accessToken");
    // if (account) {
    var config = {
      method: "get",
      url: `${Api_Url}/Institutions/stats?privateSale=GEMS`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        let value = response?.data?.data;
        // console.log("sdfsfsdfsdfsdfdsfsdfdf", value)
        setPDP(value?.purchasesAndDopPurchased);
        setPEU(value?.purchasedAndEarningsUsdt);
        setPEE(value?.purchasedAndEarningsEth);
        setIND(value?.inDirectSales);
        setDT(value?.directSales);
        setPC(value?.myPurchasesChange);
        setPMC(value?.myPurchasesAmountChange);
        setLC(value?.myLeadersChange);
        setDPC(value?.myDopPurchasedChange);
        setLEC(value?.myEarningsChange);
        setgg(value?.leaders);
        setmm(value?.megas);
        setss(value?.supers);
      })
      .catch(function (error) {
        console.log("status: ", error?.response);
        if (error?.response?.status == 401) {
          history.push("/login");
        }
        console.log(error);
      });
    // }
  };

  return (
    <>
      <section className="alltransaction new-transaction">
        <div className="custom-container">
          <button
            class="btn-back guvxgyvxvtxx"
            onClick={() => functiontogetback()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                fill="white"
              ></path>
              <path
                d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                fill="white"
              ></path>
            </svg>{" "}
            Back
          </button>
          <div className="main-heading">
            <h6>All Transactions</h6>
            {user?.role == "god_agent" && user?.specialAccess?.length > 0 ? (
              <div className="set-twice-text">
                <div className="parent-toggle-switch-custom">
                  <div class="custom-toggle">
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="checkbox"
                      checked={myTree}
                      onChange={(e) => {
                        setMyTree(e.target.checked);
                      }}
                    />
                    <label class="switch" for="checkbox">
                      <span class="slider"></span>
                    </label>
                  </div>
                </div>
                <h6>Show data from my tree only</h6>
              </div>
            ) : null}
          </div>
          {user?.role == "admin" ? (
            <div className="bottom-cards">
              <div className="single-card">
                <img
                  src="\assets\dashboard\noofpurchases.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Number of Purchases</p>
                {adminobj?.purchasesAndDopPurchased?.length > 0 ? (
                  <h6>
                    {adminobj?.purchasesAndDopPurchased[0]?.totalPurchases}
                  </h6>
                ) : (
                  <h6>0</h6>
                )}
              </div>
              <div className="single-card">
                <img src="\gemslogo.svg" alt="img" className="gems-logo-img" />
                <p>GEMS Purchased</p>
                {adminobj?.purchasesAndDopPurchased?.length > 0 ? (
                  <h6>
                    {parseInt(
                      adminobj?.purchasesAndDopPurchased[0]?.totalDopPurchased?.toFixed(
                        2
                      )
                    ).toLocaleString("en-US")}{" "}
                    &nbsp;GEMS
                  </h6>
                ) : (
                  <h6>0.00 GEMS</h6>
                )}
              </div>
              <div className="single-card">
                <img
                  src="\assets\dashboard\purchasedamount.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p>Purchased Amount</p>
                {tomiPrice && usdPrice && adminobj ? (
                  <h6 className="datahead">
                    ~$
                    {parseFloat(
                      (
                        usdPrice *
                          (adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount?.length > 0 &&
                          adminobj?.purchasedAmount[0]?._id == "ETH"
                            ? adminobj?.purchasedAmount[0]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[1]?._id == "ETH"
                            ? adminobj?.purchasedAmount[1]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[2]?._id == "ETH"
                            ? adminobj?.purchasedAmount[2]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[3]?._id == "ETH"
                            ? adminobj?.purchasedAmount[3]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[4]?._id == "ETH"
                            ? adminobj?.purchasedAmount[4]?.purchasedAmount
                            : 0) +
                        (adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount?.length > 0 &&
                        adminobj?.purchasedAmount[0]?._id == "USDT"
                          ? adminobj?.purchasedAmount[0]?.purchasedAmount
                          : adminobj?.purchasedAmount &&
                            adminobj?.purchasedAmount?.length > 0 &&
                            adminobj?.purchasedAmount[1]?._id == "USDT"
                          ? adminobj?.purchasedAmount[1]?.purchasedAmount
                          : adminobj?.purchasedAmount &&
                            adminobj?.purchasedAmount?.length > 0 &&
                            adminobj?.purchasedAmount[2]?._id == "USDT"
                          ? adminobj?.purchasedAmount[2]?.purchasedAmount
                          : adminobj?.purchasedAmount &&
                            adminobj?.purchasedAmount?.length > 0 &&
                            adminobj?.purchasedAmount[3]?._id == "USDT"
                          ? adminobj?.purchasedAmount[3]?.purchasedAmount
                          : adminobj?.purchasedAmount &&
                            adminobj?.purchasedAmount?.length > 0 &&
                            adminobj?.purchasedAmount[4]?._id == "USDT"
                          ? adminobj?.purchasedAmount[4]?.purchasedAmount
                          : 0) +
                        tomiPrice *
                          (adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount?.length > 0 &&
                          adminobj?.purchasedAmount[0]?._id == "TOMI"
                            ? adminobj?.purchasedAmount[0]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[1]?._id == "TOMI"
                            ? adminobj?.purchasedAmount[1]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[2]?._id == "TOMI"
                            ? adminobj?.purchasedAmount[2]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[3]?._id == "TOMI"
                            ? adminobj?.purchasedAmount[3]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[4]?._id == "TOMI"
                            ? adminobj?.purchasedAmount[4]?.purchasedAmount
                            : 0) +
                        (adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount?.length > 0 &&
                        adminobj?.purchasedAmount[0]?._id == "USDC"
                          ? adminobj?.purchasedAmount[0]?.purchasedAmount
                          : adminobj?.purchasedAmount &&
                            adminobj?.purchasedAmount?.length > 0 &&
                            adminobj?.purchasedAmount[1]?._id == "USDC"
                          ? adminobj?.purchasedAmount[1]?.purchasedAmount
                          : adminobj?.purchasedAmount &&
                            adminobj?.purchasedAmount?.length > 0 &&
                            adminobj?.purchasedAmount[2]?._id == "USDC"
                          ? adminobj?.purchasedAmount[2]?.purchasedAmount
                          : adminobj?.purchasedAmount &&
                            adminobj?.purchasedAmount?.length > 0 &&
                            adminobj?.purchasedAmount[3]?._id == "USDC"
                          ? adminobj?.purchasedAmount[3]?.purchasedAmount
                          : adminobj?.purchasedAmount &&
                            adminobj?.purchasedAmount?.length > 0 &&
                            adminobj?.purchasedAmount[4]?._id == "USDC"
                          ? adminobj?.purchasedAmount[4]?.purchasedAmount
                          : 0) +
                        btcPrice *
                          (adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount?.length > 0 &&
                          adminobj?.purchasedAmount[0]?._id == "WBTC"
                            ? adminobj?.purchasedAmount[0]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[1]?._id == "WBTC"
                            ? adminobj?.purchasedAmount[1]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[2]?._id == "WBTC"
                            ? adminobj?.purchasedAmount[2]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[3]?._id == "WBTC"
                            ? adminobj?.purchasedAmount[3]?.purchasedAmount
                            : adminobj?.purchasedAmount &&
                              adminobj?.purchasedAmount?.length > 0 &&
                              adminobj?.purchasedAmount[4]?._id == "WBTC"
                            ? adminobj?.purchasedAmount[4]?.purchasedAmount
                            : 0)
                      )?.toFixed(4)
                    ).toLocaleString("en-US")}{" "}
                  </h6>
                ) : (
                  <h6 className="datahead">~$0.00 USD</h6>
                )}
                <div className="bottom-text">
                  <p className="uni-text">
                    <span></span>
                    {parseFloat(
                      adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount?.length > 0 &&
                        adminobj?.purchasedAmount[0]?._id == "ETH"
                        ? adminobj?.purchasedAmount[0]?.purchasedAmount
                        : adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount[1]?._id == "ETH"
                        ? adminobj?.purchasedAmount[1]?.purchasedAmount
                        : adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount[2]?._id == "ETH"
                        ? adminobj?.purchasedAmount[2]?.purchasedAmount
                        : adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount[3]?._id == "ETH"
                        ? adminobj?.purchasedAmount[3]?.purchasedAmount
                        : adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount[4]?._id == "ETH"
                        ? adminobj?.purchasedAmount[4]?.purchasedAmount
                        : 0
                    ).toFixed(5)}{" "}
                    ETH
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {adminobj?.purchasedAmount &&
                    adminobj?.purchasedAmount?.length > 0 &&
                    adminobj?.purchasedAmount[0]?._id == "USDT"
                      ? adminobj?.purchasedAmount[0]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[1]?._id == "USDT"
                      ? adminobj?.purchasedAmount[1]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[2]?._id == "USDT"
                      ? adminobj?.purchasedAmount[2]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[3]?._id == "USDT"
                      ? adminobj?.purchasedAmount[3]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[4]?._id == "USDT"
                      ? adminobj?.purchasedAmount[4]?.purchasedAmount
                      : 0}{" "}
                    USDT
                  </p>
                  {/* <p className="uni-text">
                    <span></span>
                    {adminobj?.purchasedAmount &&
                    adminobj?.purchasedAmount?.length > 0 &&
                    adminobj?.purchasedAmount[0]?._id == "TOMI"
                      ? adminobj?.purchasedAmount[0]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[1]?._id == "TOMI"
                      ? adminobj?.purchasedAmount[1]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[2]?._id == "TOMI"
                      ? adminobj?.purchasedAmount[2]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[3]?._id == "TOMI"
                      ? adminobj?.purchasedAmount[3]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[4]?._id == "TOMI"
                      ? adminobj?.purchasedAmount[4]?.purchasedAmount
                      : 0}{" "}
                    TOMI
                  </p> */}
                  <p className="uni-text">
                    <span></span>
                    {adminobj?.purchasedAmount &&
                    adminobj?.purchasedAmount?.length > 0 &&
                    adminobj?.purchasedAmount[0]?._id == "USDC"
                      ? adminobj?.purchasedAmount[0]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[1]?._id == "USDC"
                      ? adminobj?.purchasedAmount[1]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[2]?._id == "USDC"
                      ? adminobj?.purchasedAmount[2]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[3]?._id == "USDC"
                      ? adminobj?.purchasedAmount[3]?.purchasedAmount
                      : adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount[4]?._id == "USDC"
                      ? adminobj?.purchasedAmount[4]?.purchasedAmount
                      : 0}{" "}
                    USDC
                  </p>
                  <p className="uni-text">
                    <span></span>
                    {parseFloat(
                      adminobj?.purchasedAmount &&
                        adminobj?.purchasedAmount?.length > 0 &&
                        adminobj?.purchasedAmount[0]?._id == "WBTC"
                        ? adminobj?.purchasedAmount[0]?.purchasedAmount
                        : adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount[1]?._id == "WBTC"
                        ? adminobj?.purchasedAmount[1]?.purchasedAmount
                        : adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount[2]?._id == "WBTC"
                        ? adminobj?.purchasedAmount[2]?.purchasedAmount
                        : adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount[3]?._id == "WBTC"
                        ? adminobj?.purchasedAmount[3]?.purchasedAmount
                        : adminobj?.purchasedAmount &&
                          adminobj?.purchasedAmount[4]?._id == "WBTC"
                        ? adminobj?.purchasedAmount[4]?.purchasedAmount
                        : 0
                    ).toFixed(6)}{" "}
                    WBTC
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
            // <div className="bottom-cards">
            //   <div className="single-card">
            //     <img
            //       src="\assets\dashboard\noofpurchases.svg"
            //       alt="img"
            //       className="img-fluid"
            //     />
            //     <p>Number of Purchases</p>
            //     <h6>
            //       {pdp[0]?.TotalPurchases > 0 ? pdp[0]?.TotalPurchases : 0}{" "}
            //       <span className={pc > 0 ? "green-text" : "red-text"}>
            //         {pc ? pc.toFixed(1) + "% " : ""}
            //       </span>
            //     </h6>
            //   </div>
            //   <div className="single-card">
            //     <img
            //       src="\assets\dashboard\doppurchases.svg"
            //       alt="img"
            //       className="img-fluid"
            //     />
            //     <p>DOP Purchased</p>
            //     {usdPrice && pdp ? (
            //       <h6>
            //         {pdp[0]?.totalDopPurchased > 0
            //           ? parseFloat(pdp[0]?.totalDopPurchased)
            //               .toFixed(2)
            //               ?.toLocaleString("en-US")
            //           : 0}
            //         &nbsp;DOP{" "}
            //         <span className={dpc > 0 ? "green-text" : "red-text"}>
            //           {dpc ? dpc.toFixed(1) + "% " : ""}
            //         </span>
            //       </h6>
            //     ) : (
            //       <h6>0.00 DOP</h6>
            //     )}
            //     <div className="bottom-text">
            //       <p className="uni-text">
            //         <span></span>
            //         {dt[0]?.totalDirectDop
            //           ? dt[0]?.totalDirectDop?.toFixed(4)
            //           : 0}
            //       </p>
            //       <p className="uni-text">
            //         <span></span>~
            //         {ind[0]?.totalInDirectDop
            //           ? ind[0]?.totalInDirectDop?.toFixed(4)
            //           : 0}
            //       </p>
            //     </div>
            //   </div>
            //   <div className="single-card">
            //     <img
            //       src="\assets\dashboard\purchasedamount.svg"
            //       alt="img"
            //       className="img-fluid"
            //     />
            //     <p>Purchased Amount</p>
            //     {(usdPrice && pee) || peu ? (
            //       <h6 className="datahead">
            //         {pee[0]?.totalPurchaseEth || peu[0]?.totalPurchaseUsdt
            //           ? "~$" +
            //             parseFloat(
            //               (
            //                 usdPrice *
            //                   (pee[0]?.totalPurchaseEth
            //                     ? pee[0]?.totalPurchaseEth
            //                     : 0) +
            //                 (peu[0]?.totalPurchaseUsdt
            //                   ? peu[0]?.totalPurchaseUsdt
            //                   : 0)
            //               )?.toFixed(4)
            //             ).toLocaleString("en-US")
            //           : 0}{" "}
            //         <span className={pmc > 0 ? "green-text" : "red-text"}>
            //           {pmc ? pmc.toFixed(1) + "% " : ""}
            //         </span>
            //       </h6>
            //     ) : (
            //       <h6 className="datahead">~$0.00 USD</h6>
            //     )}

            //     <div className="bottom-text">
            //       <p className="uni-text">
            //         <span></span>
            //         {pee[0]?.totalPurchaseEth
            //           ? pee[0]?.totalPurchaseEth?.toFixed(4)
            //           : 0}{" "}
            //         ETH
            //       </p>
            //       <p className="uni-text">
            //         <span></span>
            //         {peu[0]?.totalPurchaseUsdt
            //           ? parseFloat(
            //               peu[0]?.totalPurchaseUsdt?.toFixed(2)
            //             )?.toLocaleString("en-US")
            //           : 0}{" "}
            //         USDT
            //       </p>
            //     </div>
            //   </div>
            // </div>
          )}
          <div className="main-heading111">
            <div className="rightside-content">
              <div
                className={
                  userObj?.role == "mini_agent"
                    ? "custom-option-field w-100"
                    : "custom-option-field"
                }
              >
                {/* <form onSubmit={(e) => GetSearch(e)}> */}
                <input
                  onChange={(e) => {
                    if (page != 0) {
                      setPage(0);
                    }
                    setSearch(e.target.value);
                  }}
                  type="search"
                  placeholder="Search..."
                />
                {/* </form> */}
                <img
                  style={{ cursor: "pointer" }}
                  src="\assets\search-icon.svg"
                  alt="img"
                  className="img-fluid search-icon"
                  // onClick={() => GetSearch()}
                />
                <div class="dropdown transaction-inner-drop">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {searchBy == "name"
                      ? "Name"
                      : searchBy == "accessCode"
                      ? "Code"
                      : searchBy == "by"
                      ? "Wallet"
                      : ""}
                    <img
                      src="\assets\arrow-down.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={(e) => setSearchBy("name")}
                      >
                        By Name
                      </a>
                    </li>

                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={(e) => setSearchBy("accessCode")}
                      >
                        By Code
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={(e) => setSearchBy("by")}
                      >
                        By Wallet
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* {userObj?.role == "mini_agent" ? (
                ""
              ) : (
                <div class="dropdown">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div>
                      Type{" "}
                      <img
                        src="\assets\arrow-down.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </button>
                  {userObj?.role == "admin" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("god_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          God Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mega_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mega Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => setTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mini Leader
                        </a>
                      </li>
                    </ul>
                  ) : userObj?.role == "god_agent" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mega_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mega Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => setTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mini Leader
                        </a>
                      </li>
                    </ul>
                  ) : userObj?.role == "mega_agent" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() => setTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mini Leader
                        </a>
                      </li>
                    </ul>
                  ) : userObj?.role == "super_agent" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mini Leader
                        </a>
                      </li>
                    </ul>
                  ) : userObj?.role == "agent" ? (
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mini Leader
                        </a>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              )} */}
              {/* <button className="reset-btn-global">Reset</button> */}
            </div>
          </div>

          {transactionApiCalled ? (
            <>
              {transactions?.length > 0 ? (
                <DataTable
                  page={page}
                  handlePageClick={handlePageChange}
                  setSearch={setSearch}
                  search={search}
                  usdPrice={usdPrice}
                  transactions={transactions}
                  // setTransactions={setTransactions}
                  pageCount={pageCount}
                  user={user}
                  setOrderDirection={setOrderDirection}
                  setOrderField={setOrderField}
                />
              ) : (
                <p
                  className=""
                  style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
                >
                  No data found!
                </p>
              )}
            </>
          ) : (
            <p
              className=""
              style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
            >
              Loading...
            </p>
          )}

          <div className="footer-content mt-2">
            <div className="left-f">
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
              {/* <h6>SHOWING 1-10 OF 145</h6> */}
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <nav aria-label="Page navigation example">
                    <ul class="text-center">
                      <ReactPaginate
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M10 13L5 8L10 3"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M5 13L10 8L5 3"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={page}
                      ></ReactPaginate>
                    </ul>
                  </nav>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InstituteTransaction;
