let Environment = {
  PreSale: "0x6236e15ab57bAA2d1465660Ef4Ec3B0A8D071090",
  Claim: "0x96d7e90E6176466a0D882fc8BA6436021F1AeB06",
  InstitutionPreSale: "0x2E1858809393FaDb72D406Bb4E4699AD759e88fa",
  Graph: "https://api.studio.thegraph.com/query/63987/gems-presale-main/0.4.1",
  institutionGraph:
    "https://api.studio.thegraph.com/query/63987/institutionpresale-live/0.0.1",
  // Graph:
  //   "https://api.thegraph.com/subgraphs/name/saifdevblochain/gemsambassador", //previous graph for factory's claim contract

  busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  wbtc: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
};
export default Environment;
